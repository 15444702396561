<template>
  <v-app style="background-color: white;" >

    <div>

      <v-dialog v-model="dialog_cuentavalidada" persistent width="600px">
        <v-card>
          <v-card-title class="headline">CUENTA VALIDADA!!! INICIE SESION </v-card-title>
          <v-card-text class="text--primary" color="black"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="dialog_cuentavalidada = false">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errobj.errbox" persistent width="600px">
        <v-card>
          <v-card-title class="headline" v-text="errobj.errtitulo"></v-card-title>
          <v-card-text class="text--primary" color="black" v-text="errobj.errwarn"></v-card-text>
          <v-card-text class="text--primary" color="black" v-text="errobj.errtexto"></v-card-text>
          <v-card-text class="text--primary" color="black" v-text="errobj.errlink"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="errobj.errbox = false">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--class="header"-->

      <header
        :class="[this.$router.currentRoute.name == 'inicio' ? 'header-inicio' : 'header-otros bg-amber' ]">
        <v-container class="d-flex flex-column justify-center py-4">
          <v-toolbar elevation="0">
            <img src="./assets/logo-blanco.png" width="60" height="50" v-if="this.$router.currentRoute.name == 'inicio'"/>
            <img src="./assets/logo.png" width="60" height="50" v-else/>

            <v-spacer></v-spacer>

            <div class="h-100 d-none d-lg-block">
              <v-btn v-for="(item, i) in routes"
                color="black"
                dark
                text
                small
                class="rounded-0"
                height="100%"
                :to=item.route
                :key="i"
              >{{ item.text }}</v-btn>
            </div>

            <v-spacer></v-spacer>

            <div>
              <v-app-bar-nav-icon
                class="d-inline-flex d-lg-none mr-2"
                @click="expand = !expand"
              ></v-app-bar-nav-icon>

              <template v-if="!$store.state.userNadie">
                <v-menu
                  bottom
                  left
                  offset-y
                  rounded="lg"
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      color="orange"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiAccount }}</v-icon>
                      <span class="d-none d-lg-inline-block">{{ $store.state.userData.nombre }}</span>
                    </v-btn>
                  </template>

                  <v-list dense min-width="150">
                    <template v-if="$store.state.userProfesor">
                      <v-list-item-group>
                        <v-list-item
                          link
                          :to="{name:'profesorcursos'}"
                        >
                          <v-list-item-content>
                            <v-list-item-title>Cursos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          link
                          :to="{name:'profesorfinanzas'}"
                        >
                          <v-list-item-content>
                            <v-list-item-title>Finanzas</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </template>
                    <v-subheader>USUARIO</v-subheader>
                    <v-list-item-group>
                      <v-list-item
                        link
                        :to="{name: $store.state.userProfesor ? 'profesorperfil' : 'alumnoperfil' }"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Mi Perfil</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="cerrarsesion"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Cerrar sesión</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </div>
          </v-toolbar>

          <template v-if="['xs', 'sm', 'md'].includes(this.$router.app.$vuetify.breakpoint.name)">
            <v-expand-transition>
              <div
                v-show="expand"
                height="100"
                width="100%"
                class="bg-amber mt-3"
              >
                <v-list-item v-for="(item, i) in routes"
                  :key="i"
                  link
                  :to=item.route
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-expand-transition>
          </template>
        </v-container>
      </header>

    </div>
    <div>
      <v-content>
        <router-view/>
      </v-content>
    </div>
  </v-app>
</template>

<style>
  /*PARA HEADER INICIO*/
  
  .v-toolbar{
    background-color: transparent !important;
  }
  
  .header-inicio {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  /*PARA LOS OTROS HEADER*/
  .header-otros {
    width: 100%;
    margin-bottom: 10px;
  }

  .text-center{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
  }

  .h-100 {
    height: 100%;
  }

  .bg-amber {
    background-color: #ffc107 !important;
  }
</style>

<script>
import {mapState, mapMutations} from 'vuex'
import { getInfo } from './service/alumnoService'
import { mdiAccount } from '@mdi/js';

export default {
  name: 'App',
  data () {
    return {
      expand: false,
      dialog_cuentavalidada: false,
      icons: {
        mdiAccount
      },
      routes: [],
      routesProfesor: [
        { route: { name: 'profesorinicio' }, text: 'Agenda' },
        { route: { name: 'profesorhorario' }, text: 'Horario' },
        { route: { name: 'profesortaller' }, text: 'Taller' },
        { route: { name: 'profesordnd' }, text: 'Dia No Disponible' },
        { route: { name: 'profesoralumnos' }, text: 'Alumnos' },
        { route: { name: 'profesorgestionmateriales' }, text: 'Gestionar Materiales' },
      ],
      routesAlumno: [
        { route: { name: 'alumnoinicio' }, text: 'Mi Inicio' },
      ],
      routesDefault: [
        { route: '/', text: 'Inicio' },
        { route: { name: 'alumnologin' }, text: 'Iniciar Sesión' },
        { route: { name: 'profesorlogin' }, text: 'Tutor de LearnIt' },
      ],
    }
  },
  computed:{
    ...mapState([
      'userData',
      'userNadie',
      'userProfesor',
      'userAlumno',
      'currentJWT',
      'cursosBusquedaList',
      'newAsesoria',
      'id_Asesoria',
      'procesoNoTengoCuenta',
      'unirseAsesoria',
      'crearAsesoria',
      'id_Asesoria_profe',
      'horas_dictadas_x_curso',
      'id_curso_seleccionado_para_asesoria',
      'errobj',
      'storedDate'
    ])
  },
  created() {
    this.find_localStorage_exist();
  },
  updated() {
    this.userPaths();
  },
  methods:{
    ...mapMutations([
      'setJWT',
      'saveStateToLocalStorage',
      'loadStateFromLocalStorage',
      'resetSate',
      'resetNewAsesoria'
    ]),
    cerrarsesion(){
      let me = this;
      me.$store.state.userData={};
      me.$store.state.currentJWT= {};
      me.$store.state.userNadie=true;
      me.$store.state.userProfesor= false;
      me.$store.state.userAlumno= false;
      me.$store.state.procesoNoTengoCuenta = false,
      me.$store.state.unirseAsesoria = false,
      me.$store.state.crearAsesoria = false,
      me.resetNewAsesoria()
      me.saveStateToLocalStorage();
      this.$router.push({name:'inicio'})
    },
    async find_localStorage_exist(){
      if (localStorage.getItem("state") != null) {
        this.loadStateFromLocalStorage();
        this.$store.state.storedDate = new Date()

        //valdidar sesion guardada
        if (this.$store.state.currentJWT.token) {
          let respuesta = await getInfo(this.currentJWT.id, this.currentJWT.tipo);
          if (respuesta.operacion) {
            this.userData.nombre = respuesta.nombre;
            this.userData.apellido = respuesta.apellido;
            this.userData.dni = respuesta.dni;
            this.userData.universidad = respuesta.universidad;
            this.userData.celular = respuesta.celular;
            this.userData.correo = respuesta.correo;
            this.$store.state.userData = this.userData;
            this.saveStateToLocalStorage();
          }
        }
      } else{
        this.resetSate();
        this.saveStateToLocalStorage();
      }
      this.userPaths();
    },
    userPaths() {
      if (this.$store.state.userAlumno) {
        this.routes = this.routesAlumno;
      } else if (this.$store.state.userProfesor) {
        this.routes = this.routesProfesor;
      } else {
        this.routes = this.routesDefault;
      }
    }
  },
}
</script>
