<template>
    <v-form>
        <v-container>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Mis Cursos</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    label="Buscar Curso"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.estado="{ item }">
                  {{ item.estado == true? "Activo" : "NO Activo" }}
              </template>
              <template v-slot:item.individual="{ item }">
                  {{ item.individual == true? "SI individual" : "NO individual" }}
              </template>
              <template v-slot:item.grupal="{ item }" >
                  {{ item.grupal == true? "SI grupal" : "NO grupal" }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="item.estado == true" @click="changeStatus(item.id, false)" color="green" large> toggle_on </v-icon>
                <v-icon v-else @click="changeStatus(item.id, true)" color="red" large> toggle_off </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
        </v-container>
    </v-form>
</template>

<script>
  import { axiosInstance } from "../../service/index";
  export default {
    data: () => ({
      dialog: false,
      search: '',
      headers: [
        { text: 'Nombre Curso', value: 'nombre' },
        { text: 'Institucion', value: 'nombre_insitucion' },
        { text: 'Precio Individual', value: 'precio_individual' },
        { text: 'Precio Grupal', value: 'precio_grupal' },
        { text: 'Estado', value: 'estado' },
        { text: 'Activar', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.desserts = [];
        axiosInstance.get('/api/profesor/getCursosByProfesorAuth')
          .then(response => {
            if (response.data) {
              this.desserts = response.data;
            }
          });
      },

      changeStatus (id, estado) {
        axiosInstance.post(`/api/profesor/updateStatusCurso/${id}`, {
          estado
        }).then(response => {
          if (response.data.success) {
            this.initialize();
          } else {
            swal("Error", response.data.message, "error");
          }
        });
      },
    },
  }
</script>