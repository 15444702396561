<template>
  <v-form>
    <v-container>
      <template>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="150">
              <v-toolbar flat color="white">
                <v-btn outlined class="mr-4" @click="setToday">
                  HOY
                </v-btn>
                <v-btn fab text small @click="prev">
                  <v-icon small>keyboard_arrow_left</v-icon>
                </v-btn>
                <v-btn fab text small @click="next">
                  <v-icon small>keyboard_arrow_right</v-icon>
                </v-btn>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Dia</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mes</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet >
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :event-margin-bottom="3"
                :now="today"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
                first-interval="6"
                interval-count="18"
              ></v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-form>
</template>

<script>
import { getAsesoriasByProfesorFuturo } from "../../service/profesorService";
import {mapState, mapMutations} from 'vuex'
  export default {
    data: () => ({
      today: '',
      focus: '',
      type: 'week',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Dia',
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
    }),
    created () {
      this.setToday()
      this.getMisAsesorias()
    },
    computed: {
      title () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day
        const endDay = end.day  

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
            //falta formatear mejor
            return `${startDay} ${startMonth}  ${startYear} al ${endDay} ${suffixMonth}  ${suffixYear}`
          case 'day':
            return `${startDay} ${startMonth} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
      ...mapState([
        'currentJWT',
        'id_Asesoria_profe'
      ])
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      ...mapMutations([
        'saveStateToLocalStorage'
      ]),
      async getMisAsesorias () {
        var me = this;
        let respuesta = await getAsesoriasByProfesorFuturo(this.currentJWT.id, this.currentJWT.token);
        if (respuesta.operacion) {
          me.formatear_asesorias(respuesta.data)
        }
      },
      formatear_asesorias (data) {
        let me = this;
        let asesoria = {}
        for(var i = 0; i < data.length; i++){
          asesoria.id = data[i].id
          asesoria.name = data[i].nombre
          asesoria.start = data[i].fecha.split(" ")[0] + " " + data[i].hora
          data[i].hora_fin = data[i].hora_fin.replace(" ", "")
          asesoria.end = data[i].fecha.split(" ")[0] + " " + data[i].hora_fin
          if (data[i].nombre != "NO DISPONIBLE") {
            asesoria.color = 'success';
          }else{
            asesoria.color = 'error';
          }
          me.events.push(asesoria)
          asesoria = {}
        }
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today. getFullYear();

        this.today = yyyy + '-' + mm + '-' + dd;
        this.focus = this.today;
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        //nativeEvent.stopPropagation()
        if (event.name != "NO DISPONIBLE") {
          this.$store.state.id_Asesoria_profe = event.id
          this.saveStateToLocalStorage()
          this.$router.push({name:'profesorreagendar'}) 
          window.localStorage.setItem('asesoriaStorage', JSON.stringify(event));
        }
      },
      updateRange ({ start, end }) {
        // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
        this.start = start
        this.end = end
      },
    },
  }
</script>