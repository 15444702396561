<template>
  <v-form>
    <v-container>

      <v-dialog v-model="msgbox" width="600px">
        <v-card>
          <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
          <v-card-text v-text="msgtexto"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="msgbox = false">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-toolbar>
        <v-toolbar-title>Mi Perfil</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card>
        <v-row>
          <v-divider
            class="mx-4"
            vertical
          ></v-divider>
          <!--DATOS NO EDITABLES-->
          <v-col cols="12" sm="6" md="5">
                <v-text-field
                  readonly
                  v-model="profesorData.nombre"
                  label="Nombre"
                  outlined
                ></v-text-field>
                <v-text-field
                  readonly
                  v-model="profesorData.apellido"
                  label="Apellido"
                  outlined
                ></v-text-field>
                <v-text-field
                  readonly
                  v-model="profesorData.nacimiento"
                  label="Nacimiento"
                  outlined
                ></v-text-field>
                <v-text-field
                  readonly
                  v-model="profesorData.dni"
                  label="DNI"
                  outlined
                ></v-text-field>
                <v-text-field
                  readonly
                  v-model="profesorData.universidad"
                  label="Universidad"
                  outlined
                ></v-text-field>
                <v-text-field
                  readonly
                  v-model="profesorData.carrera"
                  label="Carrera"
                  outlined
                ></v-text-field>
          </v-col>
          <v-divider
            class="mx-4"
            vertical
          ></v-divider>
          <!--DATOS EDITABLES-->
          <v-col cols="12" sm="6" md="5">
            <v-form ref="form" v-model="valid">
              <v-select
                :readonly="deshabilitado"
                v-model="profesorData.ciclo_actual"
                :items="ciclos"
                label="Ciclo Actual"
                
                outlined
              ></v-select>
              <v-text-field
                :readonly="deshabilitado"
                v-model.number="profesorData.celular"
                type="number"
                label="Celular"
                outlined
                :rules="celularRules"
              ></v-text-field>
              <v-text-field
                :readonly="deshabilitado"
                v-model="profesorData.correo"
                label="Correo"
                outlined
                :rules="correoRules"
              ></v-text-field>
              <h2>Cambiar Contraseña</h2>
              <v-text-field
                :readonly="deshabilitado"
                v-model="profesorData.contrasena_old"
                type="password"
                label="Contraseña Actual"
                outlined
                hint="La contraseña debe ser mayor a 8 caracteres"
              ></v-text-field>
              <v-text-field
                :readonly="deshabilitado"
                v-model="profesorData.contrasena_new"
                type="password"
                label="Contraseña Nueva"
                outlined
                hint="La contraseña debe ser mayor a 8 caracteres"
              ></v-text-field>
              <template v-if="editando">
                <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Guardar</v-btn>
                <v-btn color="error" class="mr-4" @click="cancelar">Cancelar</v-btn>
              </template>
              <template v-else>
                <v-btn color="success" class="mr-4" @click="editar">Editar</v-btn>
              </template>
            </v-form>
          </v-col>  
        </v-row>
      </v-card>




    </v-container>
  </v-form>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import { getInfo } from '../../service/alumnoService'
import { updateProfesor } from '../../service/profesorService'

export default {
  data () {
    return {
      msgbox: false,
      msgtitulo: '',
      msgtexto: '',
      valid: false,
      editando: false,
      deshabilitado: true,
      profesorData:{
        nombre: '',
        apellido: '',
        nacimiento: '',
        dni: -1,
        universidad: '',
        carrera: '',
        ciclo_actual: -1,
        celular: -1,
        correo: '',
        contrasena_old: '',
        contrasena_new: '',
        foto_new: null
      },
      auxData:{
        ciclo_actual: -1,
        celular: -1,
        correo: '',
      },
      ciclos:[
        {text:'1',value:1},
        {text:'2',value:2},
        {text:'3',value:3},
        {text:'4',value:4},
        {text:'5',value:5},
        {text:'6',value:6},
        {text:'7',value:7},
        {text:'8',value:8},
        {text:'9',value:9},
        {text:'10',value:10},
        {text:'Egresado',value:69},
      ],
      celularRules:[
        v => !!v || 'El Celular es necesario',
        v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Celular debe tener 9 digitos'
      ],
      correoRules: [
        v => !!v || 'El Correo es necesario',
        v => /.+@.+\..+/.test(v) || 'El Correo tiene que ser valido',
        v => (v && v.length <= 50) || 'El correos debe ser menor a 50 letras'
      ]
      //
    }
  },
  computed:{
    ...mapState([
      'currentJWT'
      ])
  },
  created(){
      this.obtener();
  },
  methods:{
    validate () {
      var me = this
      if (me.$refs.form.validate()) {
        me.snackbar = true
        me.validar()
      }
    },
    async obtener(){
      let me = this
      var respuesta = await getInfo(me.currentJWT.id, me.currentJWT.token,0);
      if(respuesta.operacion){
        me.profesorData.nombre = respuesta.nombre
        me.profesorData.apellido = respuesta.apellido
        me.profesorData.nacimiento = me.procesar_fecha(respuesta.nacimiento)
        me.profesorData.dni = respuesta.dni
        me.profesorData.universidad = respuesta.universidad
        me.profesorData.carrera = respuesta.carrera
        me.profesorData.ciclo_actual = respuesta.ciclo_actual
        me.profesorData.celular = respuesta.celular
        me.profesorData.correo = respuesta.correo
        
        me.auxData.ciclo_actual = respuesta.ciclo_actual
        me.auxData.celular = respuesta.celular
        me.auxData.correo = respuesta.correo
      }else{
        me.$store.state.errobj.errtitulo = 'Error:'
        me.$store.state.errobj.errtexto = respuesta.msgtexto;
        me.$store.state.errobj.errbox = true
      }
    },
    procesar_fecha(fecha){

      let string = fecha.slice(0, 10)
      string = string.split("-")
      string = string[2] + "/" + string[1] + "/" + string[0]

      return string
    },
    editar(){
      this.editando=true;
      this.deshabilitado=false;
    },
    validar(){
      let me = this;
      if(me.profesorData.contrasena_old.length == 0
      && me.profesorData.contrasena_new.length == 0){
        me.msgbox = true,
        me.msgtitulo = 'Ingrese Contraseña'
        me.msgtexto = 'Para poder modificar "Ciclo Actual", "Celular", y/o "Correo" debe ingresar su contraseña en "Contraseña Actual"'
      }else if(me.profesorData.contrasena_old.length < 8){
        me.msgbox = true,
        me.msgtitulo = 'Contraseña Invalida'
        me.msgtexto = 'Su Contraseña Actual es incorrecta'
      }else if(me.profesorData.contrasena_new.length == 0){
        me.guardar(0)
      }else if(me.profesorData.contrasena_new.length < 8){
        me.msgbox = true,
        me.msgtitulo = 'Contraseña Nueva Invalida'
        me.msgtexto = 'Su Contraseña Nueva debe tener al menos 8 caracteres'
      }else if (me.profesorData.contrasena_new.length >= 8){
        me.guardar(1)
      }else{
        console.log('Situacion no esperada, re-armar algoritmo')
      }
    },
    async guardar(num){
      let me = this;

      // var newURL = axios.defaults.baseURL + '/api/profesor/updateProfesor?'
      var newURL = '/api/profesor/updateProfesor?' 
      + 'ciclo_actual=' + me.profesorData.ciclo_actual

      if(me.profesorData.celular.length == 0){
        newURL = newURL + '&celular=' +  me.auxData.celular
      }else{
        newURL = newURL + '&celular=' +  me.profesorData.celular
      }

      if(me.profesorData.correo.length == 0){
        newURL = newURL + '&correo=' +  me.auxData.correo
      }else{
        newURL = newURL + '&correo=' +  me.profesorData.correo
      }
      
      switch(num){
        case 0:
          newURL = newURL + '&contrasenaAnterior=' + me.profesorData.contrasena_old
          + '&contrasenaActual=' + me.profesorData.contrasena_old
          break;

        case 1:
          newURL = newURL + '&contrasenaAnterior=' + me.profesorData.contrasena_old
          + '&contrasenaActual=' + me.profesorData.contrasena_new
          break;
      }

      newURL = newURL + '&token=' + me.currentJWT.token + '&id=' + me.currentJWT.id
      
      var respuesta = await updateProfesor(newURL)
      if(respuesta.operacion){
          me.msgtitulo = "Éxito";
          me.msgtexto = respuesta.data;
          me.msgbox = true;
          me.cancelar()
          me.obtener()
      }else{
        me.$store.state.errobj.errtitulo = 'Error del Servidor:'
        me.$store.state.errobj.errtexto = respuesta.msgtexto;
        me.$store.state.errobj.errbox = true
      }
    },
    cancelar(){
      this.profesorData.ciclo_actual = this.auxData.ciclo_actual;
      this.profesorData.celular = this.auxData.celular;
      this.profesorData.correo = this.auxData.correo;
      this.profesorData.contrasena_new = '';
      this.profesorData.contrasena_old = '';

      this.editando = false;
      this.deshabilitado=true; 
    }
  },
}
</script>
