<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="titulo" :rules="tituloRules" label="Título" required></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="seleccionados" :items="etiquetas" :rules="[v => v.length > 0 || 'El campo es requerido']" multiple chips label="Etiquetas" required></v-select>
        </v-col>
        <v-col cols="12">
          <v-textarea outlined label="Mensaje" v-model="mensaje" :rules="[v => !!v || 'El campo es requerido']"
            required></v-textarea>
        </v-col>
      </v-row>

      <div class="d-flex justify-center pa-2">
        <v-btn color="info" class="mr-2" @click="onSend()" :loading="loading" :disabled="loading">
          <v-icon class="mr-2">{{ icons.mdiSend }}</v-icon>
          Enviar mensaje
        </v-btn>
        <v-btn text link :to="{ name:'profesoralumnos' }">
          <v-icon class="mr-2">{{ icons.mdiArrowLeftCircle }}</v-icon>
          Volver
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { getByProfesor } from "../../service/EtiquetaService";
import { sendDifusion } from "../../service/profesorService";
import { mdiSend, mdiArrowLeftCircle } from '@mdi/js';

export default {
  data: () => ({
    titulo: '',
    mensaje: '',
    tituloRules: [
      v => !!v || 'El campo es obligatorio',
      v => (v && v.length >= 3 && v.length <= 50) || 'El campo debe contener entre 3 y 50 caracteres',
    ],
    seleccionados: [],
    etiquetas: [],
    loading: false,
    icons: {
      mdiSend,
      mdiArrowLeftCircle
    },
  }),
  created() {
    this.getTags();
  },
  methods: {
    async getTags() {
      const etiquetas = await getByProfesor();
      this.etiquetas = etiquetas.map(item => {
        return { text: item.nombre, value: item.id };
      })
    },
    async onSend() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      const response = await sendDifusion(this.titulo, this.mensaje, this.seleccionados);
      if (!response.success) {
        this.loading = false;
        swal("Error", response.data.message, "error");
        return;
      }
      
      this.getTags();
      this.loading = false;
      this.$refs.form.reset();
      swal("Éxito", response.data.message, "success");
    },
  },
}
</script>