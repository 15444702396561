import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSession from "vue-session";

Vue.use(VueSession);
Vue.config.productionTip = true;
Vue.prototype.$hostname = 'https://learnit.innovahora.com';
// Vue.prototype.$hostname = 'http://localhost:8080';
new Vue({
	router,
	store,
	vuetify,
	icons: {
    iconfont: 'mdiSvg',
  },
	render: (h) => h(App),
}).$mount("#app");
