import Vue from 'vue'
import VueRouter from 'vue-router'

import Inicio from '../views/Inicio.vue'
import Nosotros from '../views/Nosotros.vue'
import AlumnoLogIn from '../views/AlumnoLogIn.vue'
import AlumnoCorreoExitoso from '../views/AlumnoCorreoExitoso.vue'
import ProfesorLogIn from '../views/ProfesorLogIn.vue'
import ProfesorCrearCuenta from '../views/ProfesorCrearCuenta.vue'
import AlumnoCrearCuenta from '../views/AlumnoCrearCuenta.vue'

import Mentenimiento from '../views/Mantenimiento.vue'

//flujo alumno
import AlumnoInicio from '../components/Alumno/AlumnoInicio.vue'
import AlumnoPerfil from '../components/Alumno/AlumnoPerfil.vue'
import AlumnoBuscar from '../components/Alumno/AlumnoBuscar.vue'
import AlumnoVerProfesor from '../components/Alumno/AlumnoVerProfesor.vue'
import AlumnoCrearAsesoria from '../components/Alumno/AlumnoCrearAsesoria.vue'
import AlumnoUnirseAsesoria from '../components/Alumno/AlumnoUnirseAsesoria.vue'

//flujo profesor
import ProfesorInicio from '../components/Profesor/ProfesorInicio.vue'
import ProfesorPerfil from '../components/Profesor/ProfesorPerfil.vue'
import ProfesorHorario from '../components/Profesor/ProfesorHorario.vue'
import ProfesorDiaNoDisponible from '../components/Profesor/ProfesorDiaNoDisponible.vue'
import ProfesorFinanzas from '../components/Profesor/ProfesorFinanzas.vue'
import ProfesorReagendar from '../components/Profesor/ProfesorReagendar.vue'
import ProfesorTaller from '../components/Profesor/ProfesorTaller.vue'
import ProfesorCursos from '../components/Profesor/ProfesorCursos.vue'
import ProfesorGestionMateriales from '../components/Profesor/ProfesorGestionMateriales.vue'
import ProfesorMaterial from '../components/Profesor/ProfesorMaterial.vue'
import ProfesorAlumnos from '../components/Profesor/ProfesorAlumnos.vue'
import ProfesorEtiquetas from '../components/Profesor/ProfesorEtiquetas.vue'
import ProfesorDifusion from '../components/Profesor/ProfesorDifusion.vue'

Vue.use(VueRouter)

const routes = [
  //generales
  { path: '/', name: 'inicio', component: Inicio },
  { path: '/nosotros', name: 'nosotros', component: Nosotros },
  { path: '/mantenimiento', name: 'mantenimiento', component: Mentenimiento },

  //todo alumno
  { path: '/alumno/login', name: 'alumnologin', component: AlumnoLogIn },
  { path: '/alumno/crearcuenta', name: 'alumnocrearcuenta', component: AlumnoCrearCuenta },
  { path: '/alumno/crearcuenta/correo', name: 'alumnocorreo', component: AlumnoCorreoExitoso },
  { path: '/alumno/inicio', name: 'alumnoinicio', component: AlumnoInicio },
  { path: '/alumno/perfil', name: 'alumnoperfil', component: AlumnoPerfil },
  { path: '/alumno/buscar_curso', name: 'buscar_curso', component: AlumnoBuscar },
  { path: '/alumno/profesor_perfil/:id', name: 'profesor_perfil', component: AlumnoVerProfesor},
  { path: '/alumno/crear_asesoria', name: 'crear_asesoria', component: AlumnoCrearAsesoria },
  { path: '/alumno/unirse_asesoria/:id', name: 'alumno_unirse_asesoria', component: AlumnoUnirseAsesoria },

  //todo profe
  { path: '/profesor/login', name: 'profesorlogin', component: ProfesorLogIn },
  { path: '/profesor/crearcuenta', name: 'profesorcrearcuenta', component: ProfesorCrearCuenta },
  { path: '/profesor/inicio', name: 'profesorinicio', component: ProfesorInicio },
  { path: '/profesor/perfil', name: 'profesorperfil', component: ProfesorPerfil },
  { path: '/profesor/horario', name: 'profesorhorario', component: ProfesorHorario },
  { path: '/profesor/finanzas', name: 'profesorfinanzas', component: ProfesorFinanzas },
  { path: '/profesor/reagendar', name: 'profesorreagendar', component: ProfesorReagendar },
  { path: '/profesor/dianodisponible', name: 'profesordnd', component: ProfesorDiaNoDisponible },
  { path: '/profesor/alumnos', name: 'profesoralumnos', component: ProfesorAlumnos },
  { path: '/profesor/etiquetas', name: 'profesoretiquetas', component: ProfesorEtiquetas },
  { path: '/profesor/difusion', name: 'profesordifusion', component: ProfesorDifusion },
  { path: '/profesor/taller', name: 'profesortaller', component: ProfesorTaller },
  { path: '/profesor/cursos', name: 'profesorcursos', component: ProfesorCursos },
  { path: '/profesor/gestionmateriales', name: 'profesorgestionmateriales', component: ProfesorGestionMateriales },
  { path: '/profesor/material/:id', name: 'material', component: ProfesorMaterial }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
