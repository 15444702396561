<template  class="mainMaterial">
  <v-container>

    <v-layout text-xs-center wrap>
      <v-flex xs12>
          <v-row>
            <v-col cols="12" sm="7" md="7">
                <p style="font-size: 35px"><b>{{titulo}}</b></p>
                <p style="font-size: 25px"><b>Institución: </b>{{institucion}}</p>
                <p style="font-size: 25px"><b>Curso: </b>{{curso}}</p>
                <p style="font-size: 20px">{{contenido}}</p>
                <v-btn color="#0265ba" class="mr-4" @click="descargarArchivo"><span style="color: white">DESCARGAR MATERIAL</span></v-btn>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <v-card
                class="mx-auto"
                tile
              >
                <iframe width="100%" height="300" :src="video_url">
                </iframe>
              </v-card>
              <br>
               <p style="font-size: 25px"><b>Asesor:</b> <a style="text-decoration: none" :href="this.$hostname+'/alumno/profesor_perfil/'+id_profesor+''">{{profesor}}</a></p>
            </v-col>
          </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style>
.mainMaterial{
  background-color: #f2f2f2;
}
</style>

<script>
import {mapState, mapMutations} from 'vuex'
import {getMaterialById } from '../../service/profesorService'
import {sendDocMaterial} from '../../service/alumnoService'
export default {
  data () {
    return {
      titulo: '',
      link: '',
      contenido: '',
      curso: '',
      institucion:'',
      profesor: '',
      id_profesor: '',
      id_material: '',
      video_url: '',
    }
  },
  computed:{
    ...mapState([
      'userData',
      'currentJWT'
    ]),
    idMaterial() {
      return this.$route.params.id
    }
  },
  created(){ 
    this.getMaterialById()
  },
  methods:{
    async getMaterialById(){
        let respuesta = await getMaterialById(this.idMaterial)
        if(respuesta.operacion){
          console.table(respuesta.data[0].titulo)
          this.titulo = respuesta.data[0].titulo;
          this.profesor = respuesta.data[0].profesor;
          this.institucion = respuesta.data[0].institucion;
          this.curso = respuesta.data[0].curso;
          this.link = respuesta.data[0].link_material;
          this.id_profesor = respuesta.data[0].id_profesor;
          this.contenido = respuesta.data[0].contenido;
          this.id_material = respuesta.data[0].id;
          this.video_url = respuesta.data[0].video_url;

          
          //swal("Material Creado", 'LINK: https://learnit.com.pe/profesor/gestionmateriales/'+respuesta.data.id, "success");
        }else{
          swal("ERROR", 'No se pudo obetner el material', "error");
        }
      
    },
    descargarArchivo(){

      //VALIDAR SI HAY SESION
      if(this.$store.state.userAlumno){
        let respuesta = sendDocMaterial(this.idMaterial, this.currentJWT.id, this.currentJWT.token, this.userData.correo)
        swal("¡EXITO!", 'Se envio el Material a su correo', "success");
      }else{  
        //GUARDAR ID MATERIAL
        window.localStorage.setItem('matPendingDownload', this.idMaterial);
        this.$router.push({name:'alumnologin'});
      }
    }
  }
}
</script>
