export const storage = {
  userData:{},
  userNadie: true,
  userProfesor: false,
  userAlumno: false,
  currentJWT: {},

  institucionesBusquedaList:[],
  cursosBusquedaList:[],

  newAsesoria: {
    idProfesor: -1,
    nombre_profe: '',
    tipo: -1,
    tipo_pago: '',
    idInstitucion: -1,
    idCurso: -1,
    descripcion: '',
    nombre_curso: null,
    fecha: null,
    hora_ini: null,
    hora_fin: null
  },

  //esta es para que el alumno se una a una asesoria
  id_Asesoria: -1,
  
  procesoNoTengoCuenta: false,
  unirseAsesoria: false,
  crearAsesoria: false,

  //esta es para que el profe pueda ver una asesoria suya
  id_Asesoria_profe: -1,

  //HORAS DICTADAS POR EL PROFESOR  -- VARIABLES JOSE
  horas_dictadas_x_curso: 0,

  //objeto para manejar errores
  errobj: {
    errbox: false,
    errtitulo: '',
    errwarn: '',
    errtexto: '',
    errlink: ''
  },

  //para manejar reseteo de localstorage
  storedDate: null
}