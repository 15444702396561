<template>
  <v-container>

    <v-layout column allign-center>
      <v-form ref="form" v-model="valid">
        <v-text-field v-model="loginData.correo" :counter="50" :rules="correoRules" label="Correo" required></v-text-field>
        <v-text-field v-model="loginData.contrasena" type="password" :counter="25" :rules="contrasenaRules" label="Contraseña" required></v-text-field>
        <v-row justify="center">
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Iniciar Sesión</v-btn>
          <v-btn :to="{name:'profesorcrearcuenta'}">Postular</v-btn>
        </v-row>
      </v-form>
    </v-layout>
  </v-container>
</template>


<script>
import {mapState, mapMutations} from 'vuex'
import {login} from '../service/negocioService'
export default {
  data () {

    return {

      valid:false,

      loginData:{
        correo:"",
        contrasena:""
      },

      correoRules: [
        v => !!v || 'El correo debe ser valido',
        v => /.+@.+\..+/.test(v) || 'El correo debe ser valido',
      ],

      contrasenaRules: [
        v => !!v || 'La contraseña es requerida',
        v => (v && v.length >= 8) || 'La contraseña debe ser mayor a 8 caracteres',
      ]

    }
  },
  mounted () {
    this.verifyUser();
  },
  computed:{
    ...mapState([
      'userData',
      'currentJWT',
      'errobj'
      ])
  },
  methods:{
    ...mapMutations([
      'setUserAccount',
      'changeUserNadie',
      'changeUserProfesor',
      'authProfesor',
      'setJWT',
      'saveStateToLocalStorage'
      ]),
    validate () {
      var me = this
      if (me.$refs.form.validate()) {
        me.snackbar = true
        me.userExist()
      }
    },
    verifyUser () {
      if (this.$store.state.currentJWT?.token && !this.$store.state.userNadie) {
        if (this.$store.state.userProfesor) {
          this.$router.push({ name:'profesorinicio' });
        } else {
          this.$router.push({ name:'alumnoinicio' });
        }
      }
    },
    async userExist(){
      const response = await login(this.loginData.correo, this.loginData.contrasena, 0);
      if (response.status >= 400) {
        swal("Error", response.data ? response.data.message : "El correo y la contraseña no coinciden, por favor intentar nuevamente", "error");
      } else if (response.data && !response.data.success) {
        swal("Error", response.data.message, "error");
      } else if (!response.data) {
        swal("Error", "El correo y la contraseña no coinciden, por favor intentar nuevamente", "error");
      }

      if (response.data?.success) {
        this.authProfesor();
        this.setJWT(response.data.data);
        this.setUserAccount(response.data.user);

        //Guardar sesion
        this.saveStateToLocalStorage();
        this.$router.push({name:'profesorinicio'});
      }
    },
  }
}
</script>
