<template>
<v-form >
  <v-container class="cont1">
    <v-layout text-xs-center wrap>
      <v-row justify="center">
        <v-col id="layoutBuscar" cols="12" sm="6" md="3">
          <v-autocomplete
            outlined
            color="#000000"
            background-color="yellow"
            v-model="busqueda_institucion"
            :items="institucionesBusquedaList"
            item-value="id"
            item-text="nombre"
            label="Buscar Institucion"
            hide-no-data
            solo
          ></v-autocomplete>
          <v-autocomplete
            outlined
            color="#000000"
            background-color="yellow"
            v-model="busqueda_curso"
            :items="cursosByInstitucion"
            item-value="id"
            item-text="nombre"
            label="Buscar Curso"
            solo
            no-data-text="Buscar Institucion primero"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>

  <v-container class="cont2">
    </br>
    <h2 style="color:#ec6910" class="text-center">ACERCA DE </h2>
    <h5 class="text-center">Learn It es una start up educativa, que facilita a alumnos como tu </br>
    la busqueda y reserva de una asesoria con un tutor academico</br>
    que se ajuste a tus necesidades del usuario, ofreciendo una</br>
    interfaz que elimine los riesgos y vuelva más eficientes los</br>
    procesos al comprar y recibir el servicio de la asesoria</h5>

    </br>
    </br>
    <v-row justify="center">
      <v-img
        max-height="1000"
        max-width="800"
        src="../assets/como-funciona.png"
      ></v-img>
    </v-row>


    </br>
    </br>
    <v-row justify="center">
      <v-col cols="12" sm="3">
        <v-img
          max-height="1100"
          max-width="700"
          src="../assets/1.png"
        ></v-img>
        <v-img
          max-height="1100"
          max-width="700"
          src="../assets/3.png"
        ></v-img>
      </v-col>
      <v-col cols="12" sm="2">
      </v-col>
      <v-col cols="12" sm="3">
        <v-img
          max-height="1100"
          max-width="700"
          src="../assets/2.png"
        ></v-img>
        <v-img
          max-height="1100"
          max-width="700"
          src="../assets/4.png"
        ></v-img>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="3">
        <v-img
          max-height="1100"
          max-width="700"
          src="../assets/5.png"
        ></v-img>
      </v-col>
    </v-row>
    </br>

    <h1 style="color: #ec6910" class ="text-center"><b>Con Learn It tienes acceso a ...</b></h1>
    <v-row justify="center">
      <v-col sm="8">
      <v-carousel :cycle=true interval="5000" >
        <v-carousel-item
          v-for="(item,i) in carrouselimages"
          :key="i"
          width="100%"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
    </v-row>
    </br>
    </br>
    </br>

    <v-row justify="center">
      <v-img
          max-height="1100"
          max-width="700"
          src="../assets/soporte.png"
      ></v-img>
    </v-row>

    <v-row justify="center">
      <v-col  id="postularlayout" sm="8">
        <v-btn id="btnPostular" target="_blank" :to="{name:'profesorcrearcuenta'}"><b>Postular</b></v-btn>
      </v-col>
    </v-row>


  </v-container>
  </br>
  <v-row id="footer" justify="center">
    <v-col  sm="12">
      <h2 class="text-center" style="color: #ec6910">CONTÁCTANOS</h2>
    </v-col>
    </br>
    <v-col sm="2">
    </v-col>
    <v-col justify="center" align="center" sm="2">
      <a  style="margin:30px" target="_blank" href="https://www.facebook.com/learnit.com.pe/">
        <v-img
            max-height="50"
            max-width="50"
            src="../assets/facebook512.png"
        ></v-img>
      </a>
    </v-col>
    <v-col  justify="center" align="center" sm="2">
      <a  style="margin:30px" target="_blank" href="https://m.me/learnit.com.pe">
        <v-img
            max-height="50"
            max-width="50"
            src="../assets/messenger512.png"
        ></v-img>
      </a>
    </v-col>
    <v-col justify="center" align="center" sm="2">
      <a style="margin:30px" target="_blank" href="https://wa.me/message/SJWUT4QGB6WSI1">
        <v-img
            max-height="50"
            max-width="50"
            src="../assets/whatsapp512.png"
        ></v-img>
      </a>
    </v-col>
    <v-col justify="center" align="center" sm="2">
      <a style="margin:30px" target="_blank" href="mailto:learnit.latinoamerica@gmail.com">
        <v-img
            max-height="50"
            max-width="50"
            src="../assets/gmail512.png"
        ></v-img>
      </a>
    </v-col>
    <v-col justify="center" align="center" sm="2">
    </v-col>
  </v-row>
</v-form>
</template>


<style>
  #footer{
    background-color: #1c2344;
  }
  #btnPostular{
    position: relative;
    top: 80%;
    left: 27%;
    border-radius: 90px;
    font-size: 50px;
    padding: 40px;
  }
  #postularlayout{
    background-image: url("../assets/parte-equipo.png");
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    height: 1100px;
  }
  .cont1{  
    max-width: 100%;
    background-image: url("../assets/inicio.png");
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    height: 920px;
    
  }
  .v-label.theme--light {
    color: rgba(0, 0, 0) !important;
    font-weight: bolder;
  }
  #layoutBuscar{
    margin-top: 10%;
    width: 100px;
    height: 40px;
  }
  .v-select__selection,
  .v-select__selection--comma,
  #layoutBuscar > div > div > div.v-input__slot{
    margin-left: 70%;
    border-radius: 50px;
  }
</style>


<script>
import {mapState, mapMutations} from 'vuex'
export default {
  data () {
    return {
      busqueda_institucion: null,
      busqueda_curso: null,
      cursosByInstitucion: [],
      carrouselimages: [
          {
            src:  require('../assets/carrousel1.png'),
          },
          {
            src: require('../assets/carrousel2.png'),
          },
          {
            src:  require('../assets/carrousel3.png'),
          },
          {
            src:  require('../assets/carrousel4.png'),
          },
        ],
    }
  },
  computed:{
    ...mapState([
      'institucionesBusquedaList',
      'cursosBusquedaList',
    ]),
  },
  watch: {
    busqueda_institucion () {
      if(this.busqueda_institucion != null){
        this.filtrarCursosByInstitucion();
      }
    },
    busqueda_curso () {
      if(this.busqueda_curso != null){
        this.setCursoBusqueda(this.busqueda_curso);
        this.$router.push({name:'buscar_curso'});
      }
    }
  },
   created() {
    let me = this
    me.getCursosyInstitucionesBusquedaList()
  },
  methods: {
    ...mapMutations([
      'getCursosyInstitucionesBusquedaList',
      'setCursoBusqueda'
    ]),

    filtrarCursosByInstitucion(){
      this.cursosByInstitucion = []
      this.cursosBusquedaList.forEach(element => {
        if (element.id_institucion == this.busqueda_institucion) {
          this.cursosByInstitucion.push(element)
        }
      })
    }
  }
}
</script>

