<template>
  <v-form>
    <v-container>

      <v-dialog v-model="msgbox" width="600px">
        <v-card>
          <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
          <v-card-text v-text="msgtexto"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="msgbox = false">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="horas"
        class="elevation-1"
        dense
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Mi Horario</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <template v-if="editando">
              <v-btn color="success" dark text class="mb-2" @click="guardar">Guardar</v-btn>
              <v-btn color="error" dark text class="mb-2" @click="cancelar">Cancelar</v-btn>
            </template>
            <template v-else>
              <v-btn color="success" dark text class="mb-2" @click="editar">Editar</v-btn>
            </template>
          </v-toolbar>
        </template>
        <template v-slot:item.lunes="{ item }">
          <v-btn 
            :color="(item.lunes == true ? 'success' : 'grey darken-3')"
            class="mr-4"
            :class="{'disable-events': !editando}"
            @click="cambiarHora(horas.indexOf(item), 1)"
            ></v-btn>
        </template>
        <template v-slot:item.martes="{ item }">
          <v-btn 
            :color="(item.martes == true ? 'success' : 'grey darken-3')"
            class="mr-4"
            :class="{'disable-events': !editando}"
            @click="cambiarHora(horas.indexOf(item), 2)"
            ></v-btn>
        </template>
        <template v-slot:item.miercoles="{ item }">
          <v-btn 
            :color="(item.miercoles == true ? 'success' : 'grey darken-3')"
            class="mr-4"
            :class="{'disable-events': !editando}"
            @click="cambiarHora(horas.indexOf(item), 3)"
            ></v-btn>
        </template>
        <template v-slot:item.jueves="{ item }">
          <v-btn 
            :color="(item.jueves == true ? 'success' : 'grey darken-3')"
            class="mr-4"
            :class="{'disable-events': !editando}"
            @click="cambiarHora(horas.indexOf(item), 4)"
            ></v-btn>
        </template>
        <template v-slot:item.viernes="{ item }">
          <v-btn 
            :color="(item.viernes == true ? 'success' : 'grey darken-3')"
            class="mr-4"
            :class="{'disable-events': !editando}"
            @click="cambiarHora(horas.indexOf(item), 5)"
            ></v-btn>
        </template>
        <template v-slot:item.sabado="{ item }">
          <v-btn 
            :color="(item.sabado == true ? 'success' : 'grey darken-3')"
            class="mr-4"
            :class="{'disable-events': !editando}"
            @click="cambiarHora(horas.indexOf(item), 6)"
            ></v-btn>
        </template>
        <template v-slot:item.domingo="{ item }">
          <v-btn 
            :color="(item.domingo == true ? 'success' : 'grey darken-3')"
            class="mr-4"
            :class="{'disable-events': !editando}"
            @click="cambiarHora(horas.indexOf(item), 7)"
            ></v-btn>
        </template>
      </v-data-table>
    </v-container>
  </v-form>
</template>

<style scoped>
.disable-events {
  pointer-events: none
}
</style>

<script>
import {mapState, mapMutations} from 'vuex'
import { getHorarioProfesor, updateHorario } from '../../service/profesorService'

  export default {
    data () {
      return {

        msgbox: false,
        msgtitulo: '',
        msgtexto: '',

        editando:false,
        headers: [
          { text: 'Hora', align: 'left', sortable: false, value: 'hora', align:'center' },
          { text: 'Lunes', align: 'left', sortable: false, value: 'lunes', align:'center' },
          { text: 'Martes', align: 'left', sortable: false, value: 'martes', align:'center' },
          { text: 'Miercoles', align: 'left', sortable: false, value: 'miercoles', align:'center' },
          { text: 'Jueves', align: 'left', sortable: false, value: 'jueves', align:'center' },
          { text: 'Viernes', align: 'left', sortable: false, value: 'viernes', align:'center' },
          { text: 'Sabado', align: 'left', sortable: false, value: 'sabado', align:'center' },
          { text: 'Domingo', align: 'left', sortable: false, value: 'domingo', align:'center' },
        ],
        horas: [
          { hora: '7 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '8 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '9 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '10 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '11 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '12 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '1 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '2 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '3 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '4 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '5 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '6 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '7 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '8 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '9 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
        ],
        defaultHoras: [
          { hora: '7 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '8 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '9 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '10 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '11 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '12 pm', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '1 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '2 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '3 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '4 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '5 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '6 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '7 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '8 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
          { hora: '9 am', lunes: false, martes: false, miercoles: false, jueves: false, viernes: false, sabado: false, domingo: false },
        ]
      }
    },
    computed:{
      ...mapState([
        'currentJWT'
        ])
    },
    created () {
      this.obtener()
    },

    methods: {
      async obtener(){
        let me = this;
        var respuesta = await getHorarioProfesor(me.currentJWT.id, me.currentJWT.token)
        if(respuesta.operacion){
            me.obtener_change(respuesta.data);
            me.cancelar();
        }else{
          me.$store.state.errobj.errtitulo = 'Error del Servidor:'
          me.$store.state.errobj.errtexto = respuesta.msgtexto;
          me.$store.state.errobj.errbox = true
        }
      },
      cambiarHora(item, num){
        let me = this;
        switch(num){
          case 1:
            if(me.horas[item].lunes == true){
              me.horas[item].lunes = false;
            }else if(me.horas[item].lunes == false){
              me.horas[item].lunes = true;
            }
            break;
          case 2:
            if(me.horas[item].martes == true){
              me.horas[item].martes = false;
            }else if(me.horas[item].martes == false){
              me.horas[item].martes = true;
            }
            break;
          case 3:
            if(me.horas[item].miercoles == true){
              me.horas[item].miercoles = false;
            }else if(me.horas[item].miercoles == false){
              me.horas[item].miercoles = true;
            }
            break;
          case 4:
            if(me.horas[item].jueves == true){
              me.horas[item].jueves = false;
            }else if(me.horas[item].jueves == false){
              me.horas[item].jueves = true;
            }
            break;
          case 5:
            if(me.horas[item].viernes == true){
              me.horas[item].viernes = false;
            }else if(me.horas[item].viernes == false){
              me.horas[item].viernes = true;
            }
            break;
          case 6:
            if(me.horas[item].sabado == true){
              me.horas[item].sabado = false;
            }else if(me.horas[item].sabado == false){
              me.horas[item].sabado = true;
            }
            break;
          case 7:
            if(me.horas[item].domingo == true){
              me.horas[item].domingo = false;
            }else if(me.horas[item].domingo == false){
              me.horas[item].domingo = true;
            }
            break;
        }
      },
      editar(){
        this.editando=true;
      },
      async guardar(){
        this.editando = false;
        let me = this;
        
        var respuesta = await updateHorario(me.currentJWT.id, me.currentJWT.token, me.guardar_change())
        if(respuesta.operacion){
          console.log(respuesta)
        }else{
          me.$store.state.errobj.errtitulo = 'Error del Servidor:'
          me.$store.state.errobj.errtexto = respuesta.msgtexto;
          me.$store.state.errobj.errbox = true
          me.cancelar()
        }

      },
      cancelar(){
        this.editando = false;
        for(var i = 0; i < 15; i++){
          this.horas[i].lunes = this.defaultHoras[i].lunes;
          this.horas[i].martes = this.defaultHoras[i].martes;
          this.horas[i].miercoles = this.defaultHoras[i].miercoles;
          this.horas[i].jueves = this.defaultHoras[i].jueves;
          this.horas[i].viernes = this.defaultHoras[i].viernes;
          this.horas[i].sabado = this.defaultHoras[i].sabado;
          this.horas[i].domingo = this.defaultHoras[i].domingo;
        }
      },
      obtener_change(data){
        let me = this;
        for(var i = 0; i < data.length; i++){
          let str = data[i].hora;
          let hora_exacta = str.substring( str.indexOf("T") + 1, str.indexOf(":"));
          switch(data[i].dia_semana){
            case 'lunes':
              for(var j = 0; j < 15; j++){
                if(hora_exacta == j+7){
                  me.defaultHoras[j].lunes = true
                }else{
                  continue
                }
              }
              break;
            
            case 'martes':
              for(var j = 0; j < 15; j++){
                if(hora_exacta == j+7){
                  me.defaultHoras[j].martes = true
                }else{
                  continue
                }
              }
              break;

            case 'miercoles':
              for(var j = 0; j < 15; j++){
                if(hora_exacta == j+7){
                  me.defaultHoras[j].miercoles = true
                }else{
                  continue
                }
              }
              break;
            
            case 'jueves':
              for(var j = 0; j < 15; j++){
                if(hora_exacta == j+7){
                  me.defaultHoras[j].jueves = true
                }else{
                  continue
                }
              }
              break;
            
            case 'viernes':
              for(var j = 0; j < 15; j++){
                if(hora_exacta == j+7){
                  me.defaultHoras[j].viernes = true
                }else{
                  continue
                }
              }
              break;
            
            case 'sabado':
              for(var j = 0; j < 15; j++){
                if(hora_exacta == j+7){
                  me.defaultHoras[j].sabado = true
                }else{
                  continue
                }
              }
              break;
            
            case 'domingo':
              for(var j = 0; j < 15; j++){
                if(hora_exacta == j+7){
                  me.defaultHoras[j].domingo = true
                }else{
                  continue
                }
              }
              break;
          }
        }
      },
      guardar_change(){
        let reordenado = [];
        let me = this;
        for(var i = 0; i < 15; i++){
          if(me.horas[i].lunes == true) reordenado.push({dia_semana: 'lunes', hora: ('0'+(i+7).toString()).slice(-2)+':00:00'});
          if(me.horas[i].martes == true) reordenado.push({dia_semana: 'martes', hora: ('0'+(i+7).toString()).slice(-2)+':00:00'});
          if(me.horas[i].miercoles == true) reordenado.push({dia_semana: 'miercoles', hora: ('0'+(i+7).toString()).slice(-2)+':00:00'});
          if(me.horas[i].jueves == true) reordenado.push({dia_semana: 'jueves', hora: ('0'+(i+7).toString()).slice(-2)+':00:00'});
          if(me.horas[i].viernes == true) reordenado.push({dia_semana: 'viernes', hora: ('0'+(i+7).toString()).slice(-2)+':00:00'});
          if(me.horas[i].sabado == true) reordenado.push({dia_semana: 'sabado', hora: ('0'+(i+7).toString()).slice(-2)+':00:00'});
          if(me.horas[i].domingo == true) reordenado.push({dia_semana: 'domingo', hora: ('0'+(i+7).toString()).slice(-2)+':00:00'});
        }
        return reordenado;
      }
    },
  }
</script>

