<template>
  <v-form>
    <v-container>

      <v-data-table
        :headers="headers"
        :items="asesorias"
        :search="search"
        sort-by="fecha"
        sort-desc
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Mis Asesorias</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Buscar Asesoria"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-btn color="error" text x-large >NO HAY ASESORIAS REGISTRADAS</v-btn>
        </template>
      </v-data-table>
    </v-container>
  </v-form>
</template>


<script>
  import { axiosInstance } from "../../service/index";
  import {mapState, mapMutations} from 'vuex'
  export default {
    data () {
      return {
        search:'',
        dialog: false,
        headers: [
          { text: 'Curso', value: 'nombre' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Hora Inicio', value: 'hora' },
          { text: 'Hora Fin', value: 'hora_fin' },
          { text: 'Cobrado', value: 'por_cobrar' },
        ],
        asesorias: [],
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    computed: {
      ...mapState([
        'currentJWT'
      ]),
    },

    created () {
      this.getFinanzas()
    },

    methods: {
      getFinanzas() {
        let me = this
        axiosInstance.get('/api/negocio/getFinanzas?idProfesor=' + me.currentJWT.id + '&token=' + me.currentJWT.token)
        .then(res => {
          let asesoria = {}
          let today = new Date();
          let hour = today.getHours();
          let dd = today.getDate();
          let mm = today.getMonth()+1; 
          let yyyy = today.getFullYear();
          if(dd<10) { dd='0'+dd; } 
          if(mm<10) { mm='0'+mm; } 
          today = yyyy + '-' + mm + '-' + dd
          today = today.toString()

          for(let i = 0; i < res.data.length; i++){
            if(res.data[i].fecha < today) {
              asesoria.nombre = res.data[i].nombre
              asesoria.tipo = (res.data[i].tipo == 1 ? 'Grupal' : 'Individual')
              asesoria.fecha = res.data[i].fecha
              asesoria.hora = res.data[i].hora
              asesoria.hora_fin = res.data[i].hora_fin
              asesoria.por_cobrar = res.data[i].por_cobrar
              me.asesorias.push(asesoria)
              asesoria = {}
            }
            if(res.data[i].fecha == today) {
              if(res.data[i].hora_fin <= hour) {
                asesoria.nombre = res.data[i].nombre
                asesoria.tipo = (res.data[i].tipo == 1 ? 'Grupal' : 'Individual')
                asesoria.fecha = res.data[i].fecha
                asesoria.hora = res.data[i].hora
                asesoria.hora_fin = res.data[i].hora_fin
                asesoria.por_cobrar = res.data[i].por_cobrar
                me.asesorias.push(asesoria)
                asesoria = {}
              }
            }
          }
        }).catch(err =>{
          console.log("Error: "+err)
        });
      },

      seeItem (item) {
        this.editedIndex = this.asesorias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
      },
    },
  }
</script>