import { axiosInstance } from "./index";

const prefixEtiqueta = 'api/etiquetas';

export const getByProfesor = async () => {
  let data = [];
  await axiosInstance.get(`${prefixEtiqueta}/profesor`)
  .then(async res => {
    data = res.data;
  }).catch(err => {
    console.log('hubo un error: ', err);
  });
  return data;
}

export const storeByProfesor = async (nombre) => {
  let data = {
    success: true,
    data: []
  };
  await axiosInstance.post(`${prefixEtiqueta}/profesor/crear`, {
    nombre,
  }).then(async res => {
    data.data = res.data;
  }).catch(err => {
    console.log('hubo un error: ', err);
    data.success = false;
    data.data = err.response.data || [];
    if (!err.response?.data?.message) {
      data.data.message = 'No se pudo crear la etiqueta';
    }
  });
  return data;
}

export const updateByProfesor = async (id, nombre) => {
  let data = {
    success: true,
    data: []
  };
  await axiosInstance.post(`${prefixEtiqueta}/profesor/editar/${id}`, {
    nombre,
  }).then(async res => {
    data.data = res.data;
  }).catch(err => {
    console.log('hubo un error: ', err);
    data.success = false;
    data.data = err.response.data || [];
    if (!err.response?.data?.message) {
      data.data.message = 'No se encontró la etiqueta';
    }
  });
  return data;
}

export const deleteByProfesor = async (id) => {
  let data = {
    success: true,
    data: []
  };
  await axiosInstance.delete(`${prefixEtiqueta}/profesor/eliminar/${id}`)
  .then(async res => {
    data.data = res.data;
  }).catch(err => {
    console.log('hubo un error: ', err);
    data.success = false;
    data.data = err.response.data || [];
    if (!err.response?.data?.message) {
      data.data.message = 'No se encontró la etiqueta';
    }
  });
  return data;
}