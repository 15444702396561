import {axiosInstance} from "./index";

//alumnoService -> apis que requieran que el alumno haya iniciado sesion

export const postCrearAsesoria = async (tipo, tipo_pago, idProfesor, idCurso, id, hora_ini, descripcion, hora_fin, fecha, token) => {
    var respuesta = {}
    await axiosInstance.post("/api/asesoria/postCrearAsesoria?tipo="+tipo+"&tipo_pago="+tipo_pago+"&id_Profesor="+idProfesor+"&id_Curso="+idCurso+"&id_Alumno="+id+"&lugar=por definirse&hora="+hora_ini+"&descripcion="+descripcion+"&hora_fin="+hora_fin+"&fecha="+fecha+"10:10:10&token="+token)
        .then(async res => {
            if(res.data == 'Asesoria Individual Creada'){
                respuesta.msgbox= true;
                respuesta.msgtitulo= 'Asesoria Individual Creada';
                respuesta.msgtexto= 'La información respecto al pago se le enviará a su Whatsapp';
            }else{
                respuesta.msgbox= true;
                respuesta.msgtitulo= 'Asesoria Grupal Creada';
                respuesta.msgtexto= 'La información respecto al pago se le enviará a su Whatsapp. Comparte este link para que tus compañeros se unan a la asesoria:';
                respuesta.msglink = this.$hostname + '/alumno/unirse_asesoria/' + res.data.id_asesoria_grupal.toString();
            }
        })
        .catch(err => {
            console.log('hubo un error: ', err)
        })

    return respuesta
}

export const getAsesoriasByAlumnoFuturo = async (id, token) => {
    var respuesta = []
    await axiosInstance.get('/api/asesoria/getAsesoriasByAlumnoFuturo?id_alumno=' + id + '&token=' + token)
        .then(async res => {
            respuesta.operacion = true
            respuesta.data = res.data
        }).catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion= false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true;
        })

    return respuesta
}

export const cancelarAsesoria = async (id_asesoria, id, token) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/cancelarAsesoria?id_asesoria=' + id_asesoria + '&id_alumno=' + id + '&token=' + token)
        .then(async res => {
            //console.log("Respuesta Servidor:")
            //console.log(res)
            //console.log(res.data)
            respuesta.data = res.data;
            respuesta.operacion = true;
            respuesta.dialog = false;
            respuesta.alert = true;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true
        })

    return respuesta
}

// en 2 lugares
export const getInfo = async (id, tipo) => {
    var respuesta = {}
    await axiosInstance.get('/api/negocio/getInfo?id=' + id + '&tipo='+tipo)
        .then(async res => {
            const data = res.data;
            if (data.success) {
                respuesta.operacion = true;
                if (data.data.tipo == 1) {
                    respuesta.nombre = data.data.nombre;
                    respuesta.apellido = data.data.apellido;
                    respuesta.dni = data.data.dni;
                    respuesta.universidad = data.data.universidad;
                    respuesta.celular = data.data.celular;
                    respuesta.correo = data.data.correo;
                } else {
                    respuesta.nombre = data.data.nombre;
                    respuesta.apellido = data.data.apellido;
                    respuesta.dni = data.data.dni;
                    respuesta.universidad = data.data.universidad;
                    respuesta.celular = data.data.celular;
                    respuesta.correo = data.data.correo;
                    respuesta.nacimiento = data.data.nacimiento.replace("T00:00:00.000Z","");
                    respuesta.carrera = data.data.carrera;
                    respuesta.ciclo_actual = data.data.ciclo_actual;
                }
            } else {
                respuesta.operacion = false;
                respuesta.data = data.data;
            }
        }).catch(err => {
            const response = err.response;
            respuesta.operacion = false;
            if (response) {
                respuesta.data = response.data.data;
            }
        });
    return respuesta;
}

export const updateAlumno = async (url, alumno) => {
    var respuesta = {}
    await axiosInstance.post(url, alumno)
        .then(async res => {
            respuesta.operacion = true
            respuesta.msgtitulo = 'Mensaje del Servidor:';
            respuesta.msgtexto = res.data;
            respuesta.msgbox = true;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true;
        })

    return respuesta
}

export const postUniserseAsesoria = async (idAsesoria, id, token, tipo_pago, precioFinal) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/postUniserseAsesoria?idAsesoria=' + idAsesoria + '&idAlumno=' + id + '&token=' + token + '&tipo_pago=' + tipo_pago + '&por_cobrar=' + precioFinal)
        .then(async res => {
            respuesta.operacion = true
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
        })

    return respuesta
}

export const sendDocMaterial = async (id_material, id, token, correo) => {
    var respuesta = {}
    await axiosInstance.get('/api/alumno/sendMaterial?id_material=' + id_material + '&id=' + id + '&token=' + token+ '&correo=' + correo)
        .then(async res => {
            respuesta.operacion = true
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
        })

    return respuesta
}

export const validateEmail = async (token) => {
    var respuesta = {}
    await axiosInstance.post('/api/alumno/verificarEmail', {
        token
    }).then(async res => {
        respuesta.operacion = res.data.success
        respuesta.message = res.data.message
    }).catch(err => {
        respuesta.operacion = false
        respuesta.message = 'Ocurrió un error'
        console.log('hubo un error: ', err)
    })

    return respuesta
}
