import axios from "axios";
import router from "../router/index";
import { storage } from "../store/data";

let axiosInstance = axios.create({
	baseURL: "https://backend-learnit.innovahora.com",
	// baseURL: "http://localhost:8081",
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	}
});

axiosInstance.interceptors.request.use(
	async (config) => {
		const session = JSON.parse(localStorage.getItem("state"));
		if (session?.currentJWT?.token) {
			config.params = config.params || {};
			config.params["access-token"] = session.currentJWT.token;
		}

		return config;
	},
	(error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
	function (response) {
		return response;
	},
	async function (error) {
		if (error.response.status !== 401) {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
		let session = JSON.parse(localStorage.getItem("state"));
		return await axiosInstance
			.post("/api/negocio/refresh-token", {
				id: session.currentJWT?.id,
				tipo: session.currentJWT?.tipo,
			}).then((response) => {
				const session = JSON.parse(localStorage.getItem("state"));
				const data = response.data;
				let config = error.config;
				config.params = config.params || {};
				config.params["access-token"] = data.data.token;
				session.currentJWT = data.data;
				localStorage.setItem("state", JSON.stringify(session));
				return new Promise((resolve, reject) => {
					axiosInstance.request(config)
						.then((response) => {
							resolve(response);
						})
						.catch((error) => {
							reject(error);
						});
				});
			}).catch((error) => {
				localStorage.setItem("state", JSON.stringify(storage));
				router.push("/");
				Promise.reject(error);
			});
	}
);

export { axiosInstance };
//alumnoService -> apis que requieran que el alumno haya iniciado sesion
//profesorService -> apis que requieran que el profe haya iniciado sesion
//negocioService -> apis sin token
