import Vue from 'vue'
import Vuex from 'vuex'
import { getCursos } from '../service/negocioService'
import { storage } from "./data";

Vue.use(Vuex)

export default new Vuex.Store({
  state: storage,
  mutations: {
    saveStateToLocalStorage(state){
      localStorage.setItem("state", JSON.stringify(state));
    },
    loadStateFromLocalStorage(state){
      if (localStorage.getItem("state") != null) {
        let myLocalStorage = JSON.parse(localStorage.getItem("state"))
        state.userData = myLocalStorage.userData
        state.userNadie = myLocalStorage.userNadie
        state.userProfesor = myLocalStorage.userProfesor
        state.userAlumno = myLocalStorage.userAlumno
        state.currentJWT = myLocalStorage.currentJWT
        state.institucionesBusquedaList = myLocalStorage.institucionesBusquedaList
        state.cursosBusquedaList = myLocalStorage.cursosBusquedaList
        state.newAsesoria = myLocalStorage.newAsesoria
        state.id_Asesoria = myLocalStorage.id_Asesoria
        state.procesoNoTengoCuenta = myLocalStorage.procesoNoTengoCuenta
        state.unirseAsesoria = myLocalStorage.unirseAsesoria
        state.crearAsesoria = myLocalStorage.crearAsesoria
        state.id_Asesoria_profe = myLocalStorage.id_Asesoria_profe
        state.horas_dictadas_x_curso = myLocalStorage.horas_dictadas_x_curso
        state.id_curso_seleccionado_para_asesoria = myLocalStorage.id_curso_seleccionado_para_asesoria
        state.errobj = myLocalStorage.errobj
        state.storedDate = myLocalStorage.storedDate
      }
    },
    resetSate(state){
      state = storage;
    },
    resetNewAsesoria(state){
      state.newAsesoria = {
        idProfesor: -1,
        nombre_profe: '',
        tipo: -1,
        tipo_pago: '',
        idCurso: -1,
        descripcion: '',
        nombre_curso: null,
        fecha: null,
        hora_ini: null,
        hora_fin: null
      }
    },
    setUserAccount(state, user) {
      state.userData = user
    },
    changeUserNadie(state) {
      state.userNadie = (state.userNadie == true? false: true)
    },
    changeUserProfesor(state) {
      state.userProfesor = (state.userProfesor == true? false: true)
    },
    changeUserAlumno(state) {
      state.userAlumno = (state.userAlumno == true? false: true)
    },
    authProfesor(state) {
      state.userNadie = false;
      state.userAlumno = false;
      state.userProfesor = true;
    },
    setJWT(state, jwt) {
      state.currentJWT = jwt;
    },
    setCursoBusqueda(state, idCurso){
      state.newAsesoria.idCurso = idCurso;

      for (let i=0; i < state.cursosBusquedaList.length; i++) {
        if (state.cursosBusquedaList[i].id == idCurso){
          state.newAsesoria.nombre_curso = state.cursosBusquedaList[i].nombre
          state.newAsesoria.idInstitucion = state.cursosBusquedaList[i].id_institucion
        }
      }
    },
    async getCursosyInstitucionesBusquedaList(state){

      var resultado = await getCursos()
      if(resultado.operacion){
        state.institucionesBusquedaList = resultado.data.instituciones;
        state.cursosBusquedaList = resultado.data.cursos;
        state.errobj.errtitulo = "";
        state.errobj.errtexto = "";
        state.errobj.link = "";
        state.errobj.errbox = false;
      }else{
        state.institucionesBusquedaList = []
        state.cursosBusquedaList = []
        state.errobj.errtitulo = "ERROR";
        state.errobj.errtexto = 'Hubo un error al momento de obtener los Cursos.'
        state.errobj.link = 'lik';
        state.errobj.errbox = true;
        console.log(resultado.error)
      }
     
    }
  },
  modules: {
  }
})
