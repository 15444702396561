<template>
  <v-container>

    <v-dialog  v-model="msgbox" width="500px">
      <v-card>
          <v-row>
            <v-col  cols="1" sm="1" md="1">
            </v-col>
            <v-col  cols="10" sm="10" md="10">
              <h2 class="text-center">INFORMACIÓN MATERIAL</h2>
               <v-text-field
                  v-model="newMaterialEdit.titulo"
                  label="TITULO"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newMaterialEdit.link"
                  label="LINK DE MATERIAL"
                  outlined
                  required
                ></v-text-field>
                <v-textarea
                  label="CONTENIDO"
                  persistent-hint
                  v-model="newMaterialEdit.contenido"
                  hint="Escriba aquí"
                  required
                  auto-grow
                  outlined
                ></v-textarea>
                <v-text-field
                  v-model="newMaterialEdit.url"
                  label="LINK DE LANDING"
                  outlined
                  disabled
                  required
                ></v-text-field>
            </v-col>
            <v-col  cols="1" sm="1" md="1">
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-btn rounded :disabled="!valid" color="#ffc107" class="mr-4" @click="editarMaterial">Editar</v-btn>
            <v-btn rounded :disabled="!valid" color="#ffc107" class="mr-4" @click="Atras">Atrás</v-btn>
          </v-row>
      </v-card>
    </v-dialog>

    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-form ref="form" v-model="valid">


          <v-row>
            <v-col cols="12" sm="6" md="6">
               <v-text-field
                  v-model="newMaterial.titulo"
                  label="TITULO"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newMaterial.link"
                  label="LINK DE MATERIAL"
                  outlined
                  required
                ></v-text-field>
                <v-textarea
                  label="CONTENIDO"
                  persistent-hint
                  v-model="newMaterial.contenido"
                  hint="Escriba aquí"
                  required
                  auto-grow
                  outlined
                ></v-textarea>
                <v-autocomplete
                  rounded
                  outlined
                  required
                  color="#000000"
                  background-color="#ffc107"
                  v-model="busqueda_institucion"
                  :items="institucionesBusquedaList"
                  item-value="id"
                  item-text="nombre"
                  label="Buscar Institucion"
                  hide-no-data
                  solo
                ></v-autocomplete>
                <v-autocomplete
                  rounded
                  required
                  outlined
                  color="#000000"
                  background-color="#ffc107"
                  v-model="busqueda_curso"
                  :items="cursosByInstitucion"
                  item-value="id"
                  item-text="nombre"
                  label="Buscar Curso"
                  solo
                  no-data-text="Buscar Institucion primero"
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-card
                class="mx-auto"
                tile
              >
                <v-list rounded>
                  <v-subheader>LANDINGS CREADAS</v-subheader>
                  <v-list-item-group
                    v-model="selectedItem"
                    color="#ffc107"
                  >
                    <v-list-item
                      v-for="(item, i) in materiales"
                      :key="i"
                      @click="verMaterial(item.id)"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.id"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.nombre"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-btn rounded :disabled="!valid" color="#ffc107" class="mr-4" @click="crearGestionMateriales">Crear</v-btn>
          </v-row>

        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import {mapState, mapMutations} from 'vuex'
import {postGestionMaterialAsesoriaProfe,getGestionMaterialAsesoriaProfe,updateGestionMaterialAsesoriaProfe } from '../../service/profesorService'
export default {
  data () {
    return {
      valid: false,
      busqueda_institucion: '',
      busqueda_curso: '',
      cursosByInstitucion: [],
      msgbox: false,
      msgtitulo: '',
      msgtexto: '',
      newMaterial: {
        titulo: '',
        link: '',
        contenido: ''
      },
      newMaterialEdit: {
        id_material: '',
        titulo: '',
        link: '',
        url: '',
        contenido: ''
      },
      selectedItem: 1,
      materiales: [],
    }
  },
  computed:{
    ...mapState([
      //'newAsesoria',
      'institucionesBusquedaList',
      'cursosBusquedaList',
      'userData',
      'userAlumno',
      'currentJWT',
      'userProfesor',
      'id_Asesoria',
      'procesoNoTengoCuenta'
      ]),
      
  },
  watch: {
    busqueda_institucion () {
      if(this.busqueda_institucion != null){
        this.filtrarCursosByInstitucion();
      }
    },
    busqueda_curso () {
      if(this.busqueda_curso != null){
        this.setCursoBusqueda(this.busqueda_curso);
      }
    }
  },
  created(){ 
    this.getGestionMateriales()
    this.getCursosyInstitucionesBusquedaList()
  },
  methods:{
    ...mapMutations([
      'getCursosyInstitucionesBusquedaList',
      'setCursoBusqueda'
    ]),
    filtrarCursosByInstitucion(){
      this.cursosByInstitucion = []
      this.cursosBusquedaList.forEach(element => {
        if (element.id_institucion == this.busqueda_institucion) {
          this.cursosByInstitucion.push(element)
        }
      })
    },
    async crearGestionMateriales(){
      if(this.newMaterial.titulo == "" || this.newMaterial.link == "" ||
        this.newMaterial.contenido == "" || this.busqueda_curso == "" ||
        this.busqueda_institucion=="" || !/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(this.newMaterial.link)){
          swal("Alerta", "Por favor ingrese todos los datos", "warning");
      } else {
        let respuesta = await postGestionMaterialAsesoriaProfe(this.currentJWT.id, this.currentJWT.token,
        this.newMaterial.titulo,  this.newMaterial.link, this.newMaterial.contenido, this.busqueda_curso,this.busqueda_institucion)
        if(respuesta.operacion) {
          this.getGestionMateriales();
          swal("Material Creado", 'LINK: ' + this.$hostname + '/profesor/material/' + respuesta.data.id, "success");
          this.newMaterial.titulo =''
          this.newMaterial.link = ''
          this.newMaterial.contenido = ''
          this.busqueda_curso = ''
          this.busqueda_institucion = ''
        } else {
          swal("ERROR", 'No se pudo crear el material', "error");
        }
      }
    },
    async getGestionMateriales(){
        this.materiales = [];
        let respuesta = await getGestionMaterialAsesoriaProfe(this.currentJWT.id, this.currentJWT.token)
        if(respuesta.operacion){
          for (var i =0; i < respuesta.data.length; i++){
            this.materiales.push({"nombre": respuesta.data[i].titulo, "id": respuesta.data[i].id})
          }
        }else{
          swal("ERROR", 'No se pudo crear el material', "error");
        }
    },
    async verMaterial(id){
      this.msgbox = true;
      let respuesta = await getGestionMaterialAsesoriaProfe(this.currentJWT.id, this.currentJWT.token)
        if(respuesta.operacion){
          for (var i =0; i < respuesta.data.length; i++){
            if(id == respuesta.data[i].id){
              this.newMaterialEdit.id_material = id
              this.newMaterialEdit.url = this.$hostname + "/profesor/material/" + id
              this.newMaterialEdit.titulo = respuesta.data[i].titulo
              this.newMaterialEdit.link = respuesta.data[i].link_material
              this.newMaterialEdit.contenido = respuesta.data[i].contenido
            }
          }
        }else{
          swal("ERROR", 'No se pudo crear el material', "error");
        }
    },
    async editarMaterial(){
        let respuesta = await updateGestionMaterialAsesoriaProfe(this.currentJWT.id, this.currentJWT.token,
        this.newMaterialEdit.titulo,  this.newMaterialEdit.link, this.newMaterialEdit.contenido,this.newMaterialEdit.id_material)
        if(respuesta.operacion){
          this.msgbox = false;
          swal("OK", 'Se modifico los datos del Material', "success");
          this.getGestionMateriales();
        }else{
          swal("ERROR", 'No se pudo crear el material', "error");
        }
    },
    Atras(){
      this.msgbox = false;
    }
  }
}
</script>
