<template>
  <v-container>
    <v-layout text-xs-center wrap>
      <v-row justify="center">

        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined
            color="#000000"
            background-color="yellow"
            v-model="busqueda_institucion"
            :items="institucionesBusquedaList"
            item-value="id"
            item-text="nombre"
            label="Buscar Institucion"
            hide-no-data
            solo
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined
            color="#000000"
            background-color="yellow"
            v-model="busqueda_curso"
            :items="cursosByInstitucion"
            item-value="id"
            item-text="nombre"
            label="Buscar Curso"
            solo
            no-data-text="Buscar Institucion primero"
          >
          </v-autocomplete>
        </v-col>

        <v-tabs grow centered color="black" slider-color="black" slider-size="5">
          <v-tab active-class="backgroud: red">Tutores</v-tab>
          <v-tab-item>
            <template v-if="newAsesoria.idCurso == -1">
              <v-row justify="center">
                <v-btn text color="error" x-large> Busque un Curso</v-btn>
              </v-row>
            </template>
            <template v-else>
              <v-card width="592" class="d-inline-block mx-auto" v-for="(item, i) in profesores" :key="i">
                <v-container>
                  <v-row justify="space-between">
                    <v-col dense cols="auto">
                      <v-img
                        height="200"
                        width="200"
                        :src="item.foto_url"
                      ></v-img>
                    </v-col>
                    <v-col dense>
                      <v-row dense class="flex-column ma-0 fill-height" justify="center">
                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Nombre Tutor"
                          :value="(item.nombre + ' ' + item.apellido)"
                        ></v-text-field>
                      
                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Horas dictadas del curso"
                          v-model="item.horas"
                        ></v-text-field>
                      

                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Precio Asesoria Grupal por persona"
                          prefix="S/."
                          v-model="item.precio_grupal"
                        ></v-text-field>

                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Precio Asesoria Individual por persona"
                          prefix="S/."
                          v-model="item.precio_individual"
                        ></v-text-field>

                        <v-btn color="success" @click="imageclick(item.id, item.horas)">Detalles</v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-row v-if="profesores == []" justify="center">
                <v-btn text color="error" x-large>Upps, parece que no tenemos un tutor que dicte ese curso</v-btn>
              </v-row>
            </template>
          </v-tab-item>
          <v-tab active-class="backgroud: red">Asesorias Abiertas</v-tab>
          <v-tab-item >
            <template v-if="newAsesoria.idCurso == -1">
              <v-row justify="center">
                <v-btn text color="error" x-large> Busque un Curso</v-btn>
              </v-row>
            </template>
            <template v-else>
              <template v-if="asesorias == false">
                <v-row justify="center">
                  <v-btn text color="error" x-large> No hay Asesorias Abiertas para este Curso</v-btn>
                </v-row>
              </template>
              <template v-else>
                <v-card width="592" class="d-inline-block mx-auto" v-for="(item, i) in asesorias" :key="i">
                  <v-container >
                    <v-row justify="space-between">
                      <v-col dense cols="auto">
                        <v-img
                          height="200"
                          width="200"
                          :src="item.foto_url"
                        ></v-img>
                      </v-col>

                      <v-col dense>
                        <v-row dense class="flex-column ma-0 fill-height" justify="center">

                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Nombre"
                            v-model="item.profesor"
                          ></v-text-field>
                          
                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Fecha"
                            :value="item.fecha"
                          ></v-text-field>

                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Hora Inicio"
                            v-model="item.hora"
                          ></v-text-field>

                          <v-text-field
                            dense
                            outlined
                            readonly
                            label="Precio por persona"
                            prefix="S/."
                            v-model="item.precioFinal"
                          ></v-text-field>

                          <v-btn color="success" @click="imageclick2(item.id)">Detalles</v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </template>
            </template>
          </v-tab-item>
        </v-tabs>
      </v-row>
      
    </v-layout>
  </v-container>
</template>

<style>
#bolddd {
    color: rgba(0, 0, 0) !important;
    font-weight: bolder;
  }
</style>

<script>
import { axiosInstance } from "../../service/index";
import {mapState, mapMutations} from 'vuex'
import {getProfesoresByCourse} from '../../service/negocioService'
export default {
  data () {
    return {
      busqueda_institucion: null,
      busqueda_curso: null,
      cursosByInstitucion: [],
      profesores:[
      ],
      asesorias:[
      ],
      teachers: []
      //
    }
  },
  computed:{
    ...mapState([
      'institucionesBusquedaList',
      'cursosBusquedaList',
      'newAsesoria',
      'horas_dictadas_x_curso'
    ])
  },
  created () {
    let me = this
    if(me.cursosBusquedaList.length == 0 || this.institucionesBusquedaList.length == 0){
      me.getCursosyInstitucionesBusquedaList()
    }
    if(me.newAsesoria.idCurso != -1){
      me.busqueda_institucion = me.newAsesoria.idInstitucion
      me.filtrarCursosByInstitucion()
      me.busqueda_curso = me.newAsesoria.idCurso      
      me.saveStateToLocalStorage();
    }
  },
  watch: {
    busqueda_institucion () {
      if(this.busqueda_institucion != null){
        this.filtrarCursosByInstitucion();
      }
    },
    busqueda_curso () {
      if(this.busqueda_curso != null){
        this.setCursoBusqueda(this.busqueda_curso);
        this.getProfesores();
        this.getAsesorias();
        this.saveStateToLocalStorage();
      }
    }
  },
  methods: {

    ...mapMutations([
      'getCursosyInstitucionesBusquedaList',
      'saveStateToLocalStorage',
      'setCursoBusqueda',
    ]),

    filtrarCursosByInstitucion(){
      this.cursosByInstitucion = []
      this.cursosBusquedaList.forEach(element => {
        if (element.id_institucion == this.busqueda_institucion) {
          this.cursosByInstitucion.push(element)
        }
      })
    },

    imageclick(id, horas){
      this.$router.push({path:'/alumno/profesor_perfil/' + id})
      this.$store.state.horas_dictadas_x_curso = horas
    },

    imageclick2(id){
      this.$router.push({path:'/alumno/unirse_asesoria/' + id})

    },

    async getProfesores(){
      let me = this
      try{
        this.profesores = await getProfesoresByCourse(me.newAsesoria.idCurso)
      }catch(e){
        console.log('error encontrado en la consulta: ',e)
      }
    },

    getAsesorias(){
      let me = this
      let auxURL = '/api/negocio/getAsesoriasAbiertasByCurso?idCurso=' + me.newAsesoria.idCurso
      axiosInstance.get(auxURL)
      .then(res => {
        me.procesarAsesoria(res.data)
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },

    procesarAsesoria(data){
      let me = this;
      for(var i = 0; i < data.length; i++){
        let str = data[i].fecha;
        let fecha_exacta = str.replace("T00:00:00.000Z","");
        data[i].fecha = fecha_exacta;
      }
      me.asesorias = data;
    },

    // async asignar_foto(){
    //   let me = this;
    //   for(var i = 0; i < me.profesores.length; i++){
    //     me.profesores[i].foto_url = ''
    //     await me.get_foto(me.profesores[i],me.profesores[i].id)
    //   }
    //   console.log('DIEGO', me.profesores)
    // }

  }
}
</script>

