<template>
  <v-container>
    <v-data-table :headers="headers" :items="etiquetas" :search="search" class="elevation-1" :items-per-page="10">
      <template v-slot:top>
        <div class="d-flex flex-column pa-2">
          <v-toolbar flat>
            <v-toolbar-title>Etiquetas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="success" class="mr-2" dark @click="openDialog()">
              <v-icon class="mr-2">{{ icons.mdiPlusCircle }}</v-icon>
              Agregar nuevo
            </v-btn>
          </v-toolbar>
          <div class="px-4 mb-4">
            <v-text-field v-model="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="warning" class="mr-2" dark small @click="onEdit(item)">
          <v-icon>{{ icons.mdiPencil }}</v-icon>
        </v-btn>
        <v-btn color="error" dark small @click="openDeleteDialog(item.id)">
          <v-icon>{{ icons.mdiDelete }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <div class="my-2">
          <p class="mb-1">No se encontraron etiquetas</p>
          <v-btn color="primary" @click="getTags">
            Reset
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog persistent max-width="600" v-model="dialog.show">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">{{ dialog.title }}</v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-text-field label="Nombre *" v-model="form.nombre" :rules="rules"></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="success" :loading="dialog.loading" :disabled="dialog.loading" text @click="saveDialog()">
              Guardar
            </v-btn>
            <v-btn text @click="closeDialog()">Cerrar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog.show" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Eliminar etiqueta</v-card-title>
        <v-card-text class="text-center">¿Está seguro(a) que desea eliminar la etiqueta?</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="red darken-1" text :loading="deleteDialog.loading" :disabled="deleteDialog.loading"
            @click="onDelete()">
            Eliminar
          </v-btn>
          <v-btn text @click="closeDeleteDialog()">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { getByProfesor, storeByProfesor, updateByProfesor, deleteByProfesor } from "../../service/EtiquetaService";
import { mdiPlusCircle, mdiPencil, mdiDelete } from '@mdi/js';

export default {
  data: () => ({
    icons: {
      mdiPlusCircle,
      mdiPencil,
      mdiDelete
    },
    dialog: {
      id: 0,
      title: "Nueva etiqueta",
      show: false,
      isCreate: true,
      loading: false,
    },
    deleteDialog: {
      id: 0,
      show: false,
      loading: false,
    },
    search: '',
    headers: [
      { text: 'Nombre', value: 'nombre' },
      { text: 'Opciones', value: 'actions', align: 'center', width: '20%' },
    ],
    etiquetas: [],
    form: {
      nombre: ''
    },
    rules: [
      value => !!value || 'El campo es requerido',
      value => (value && value.length <= 100) || 'Máximo 100 caracteres',
    ],
  }),
  created() {
    this.getTags();
  },
  methods: {
    async getTags() {
      this.etiquetas = await getByProfesor();
    },
    openDialog(title = "Nueva etiqueta", isCreate = true, id = 0) {
      this.dialog.title = title;
      this.dialog.show = true;
      this.dialog.isCreate = isCreate;
      this.dialog.id = id;
    },
    closeDialog() {
      this.dialog.show = false;
      this.dialog.isCreate = true;
      this.dialog.loading = false;
      this.dialog.id = 0;
      this.$refs.form.reset();
    },
    saveDialog() {
      if (this.dialog.isCreate) {
        this.onSave();
      } else {
        this.onUpdate();
      }
    },
    openDeleteDialog(id) {
      this.deleteDialog.show = true;
      this.deleteDialog.id = id;
    },
    closeDeleteDialog() {
      this.deleteDialog.show = false;
      this.deleteDialog.id = 0;
      this.deleteDialog.loading = false;
    },
    async onSave() {
      this.dialog.loading = true;
      if (!this.$refs.form.validate()) {
        this.dialog.loading = false;
        return;
      }
      const response = await storeByProfesor(this.form.nombre);
      if (!response.success) {
        this.dialog.loading = false;
        swal("Error", response.data.message, "error");
        return;
      }

      this.closeDialog();
      this.getTags();
      swal("Éxito", response.data.message, "success");
    },
    onEdit(item) {
      this.form.nombre = item.nombre;
      this.openDialog('Editar etiqueta', false, item.id);
    },
    async onUpdate() {
      this.dialog.loading = true;
      if (!this.$refs.form.validate()) {
        this.dialog.loading = false;
        return;
      }

      if (this.dialog.id) {
        const response = await updateByProfesor(this.dialog.id, this.form.nombre);
        if (!response.success) {
          this.dialog.loading = false;
          swal("Error", response.data.message, "error");
          return;
        }

        this.closeDialog();
        this.getTags();
        swal("Éxito", response.data.message, "success");
      } else {
        this.dialog.loading = false;
      }
    },
    async onDelete() {
      this.deleteDialog.loading = true;
      const response = await deleteByProfesor(this.deleteDialog.id);
      
      this.closeDeleteDialog();
      if (!response.success) {
        swal("Error", response.data.message, "error");
        return;
      } else {
        this.getTags();
        swal("Éxito", response.data.message, "success");
      }
    }
  },
}
</script>