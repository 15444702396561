<template>
  <v-container>

    <v-layout text-xs-center wrap>
      <template v-if="unirseAsesoria == true">
        <v-row justify="center">
          <p class="font-weight-black">INICIE SESIÓN O CREE UNA CUENTA PARA UNIRSE A LA ASESORÍA</p>
          <p class="font-weight-black">(SI CREA UNA CUENTA, LUEGO DEBE INICIAR SESIÓN)</p>
        </v-row>
      </template>
      <template v-if="crearAsesoria == true">
        <v-row justify="center">
          <p class="font-weight-black">INICIE SESIÓN O CREE UNA CUENTA PARA CREAR LA ASESORÍA</p>
          <p class="font-weight-black">(SI CREA UNA CUENTA, LUEGO DEBE INICIAR SESIÓN)</p>
        </v-row>
      </template>
      <v-flex xs12>
        <v-form ref="form" v-model="valid">


          <v-text-field 
            v-model="nuevoAlumno.nombre" 
            :counter="50" 
            :rules="nombreRules"
            label="Nombres"
            required 
          ></v-text-field>
          
          <v-text-field 
            type="number"
            :rules="celularRules"
            v-model.number="nuevoAlumno.celular" 
            label="Celular"
          ></v-text-field>

          <v-select
            v-model="nuevoAlumno.universidad"
            :items="universidades"
            item-value="value"
            chips
            label="Selecciona una universidad"
          ></v-select>

          <v-text-field
            v-model="nuevoAlumno.correo"
            :rules="correoRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-text-field
            type="password"
            v-model="contrasena1"
            :rules="contrasenaRules"
            label="Contraseña"
            required
          ></v-text-field>

          <v-text-field
            type="password"
            v-model="contrasena2"
            :rules="contrasenaRules"
            label="Confirmar Contraseña"
            required
          ></v-text-field>

          <v-row justify="center">
            <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Crear Cuenta</v-btn>
            <v-btn color="warning" class="mr-4" :to="{name:'alumnologin'}">Regresar</v-btn>
          </v-row>

        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import {mapState, mapMutations} from 'vuex'
import {newAlumno} from '../service/negocioService'
export default {
  data () {
    return {

      nuevoAlumno:
      {
        nombre:'',
        celular:null,
        universidad:null,
        correo:'',
        contrasena:'',
      },
      contrasena1:'',
      contrasena2:'',
      valid: false,

      nombreRules:[
        v => !!v || 'Nombre es necesario',
        v => (v && v.length <= 50) || 'El Nombre debe ser menor a 10 letras'
      ],
      universidades:[
        {text:'USIL - Universidad San Ignacio de Loyola', value:'USIL'},
        {text:'UPC - Universidad Peruana de Ciencias Aplicadas', value:'UPC'}
      ],
      celularRules:[
        v => !!v || 'El Celular es necesario',
        v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Celular debe tener 9 digitos'
      ],
      correoRules: [
        v => !!v || 'E-mail es necesario',
        v => /.+@.+\..+/.test(v) || 'El E-mail tiene que ser valido',
        v => (v && v.length <= 50) || 'El correo debe ser menor a 50 letras'
      ],
      contrasenaRules: [
        v => !!v || 'Es necesario completar el campo',
        v => (v && v.length >= 8) || 'La contraseña  debe ser mayor a 8 letras'
      ]
    }
  },
  computed:{
    ...mapState([
      'procesoNoTengoCuenta',
      'unirseAsesoria',
      'crearAsesoria',
      'id_Asesoria',
      'errobj'
      ])
  },
  methods:{
    save (date) {
      this.$refs.menu.save(date)
    },
    validate () {
      let me = this
      if (me.$refs.form.validate()) {
        me.snackbar = true
        me.crearAlumno()
      }
    },
    async crearAlumno(){
      let me = this;
      if(me.contrasena1 == me.contrasena2){
        me.nuevoAlumno.contrasena = me.contrasena1;
        let newURL = me.crearURL();
        let respuesta = await newAlumno(newURL);
        if(respuesta.operacion == 1) {
          if (respuesta.data.success) me.$router.push({name: 'alumnocorreo'});
          else swal("Error", respuesta.data.message, "error");
        } else if (respuesta.operacion == 2) {
          //mensaje global de error nos bota error del servidor
          swal("Error", "Ups! Ocurrió un error", "error");
        }
      }else{
        me.msgError(3,'asd')
      }
    },
    crearURL(){
      let me = this;
      let newURL;
      newURL = '/api/alumno/newAlumno?nombre=' + me.nuevoAlumno.nombre
      newURL = newURL + '&celular=' + me.nuevoAlumno.celular.toString()
      newURL = newURL + '&universidad=' + me.nuevoAlumno.universidad
      newURL = newURL + '&correo=' + me.nuevoAlumno.correo.toLowerCase().trim()
      newURL = newURL + '&contrasena=' + me.nuevoAlumno.contrasena
      
      return newURL
    },
    inArray(needle,haystack){
      let count=haystack.length;
      for(var i=0;i<count;i++)
      {
        if(haystack[i]===needle){return true;}
      }
      return false;
    },
    msgError(num,msg){
      let me = this;
      switch(num){
        case 0:
          me.errobj.errtitulo = 'Creado correctamente - Revisar Correo'
          me.errobj.errtexto = 'Se ha creado correctamente su cuenta, por favor revise su correo para validar su cuenta'
          
          me.limpiar()
          break;
        case 1:
          me.errobj.errtitulo = 'Los Datos ya existen'
          me.errobj.errtexto = 'Los datos correo y celular ya existen';
          break;
        case 3:
          me.errobj.errtitulo = 'Contraseñas no coinciden'
          me.errobj.errtexto = 'Porfavor volver a ingresar las contraseñas'
          break;
      }
      me.errobj.errbox = true;
    },
    limpiar(){
      let me = this;
      me.nuevoAlumno.nombre = '';
      me.nuevoAlumno.celular = null;
      me.nuevoAlumno.universidad = null;
      me.nuevoAlumno.correo = '';
      me.nuevoAlumno.contrasena = '';
      me.contrasena1 = '';
      me.contrasena2 = '';
    }
  }
}
</script>
