import { axiosInstance } from "./index";

//profesorService -> apis que requieran que el profe haya iniciado sesion

export const setDiaNoDisponible = async (fechainicio, fechafin, horainicio, horafin, id, token) => {
    var respuesta = {}
    await axiosInstance.post('/api/profesor/setDiaNoDisponible?fechaInicio=' + fechainicio + '&fechaFin=' + fechafin + '&horaInicio=' + horainicio + ':00:00&horaFin=' + horafin + ':00:00&id=' + id + '&token=' + token)
        .then(async res => {
            respuesta.operacion = true
            respuesta.msgbox = true
            respuesta.msgtitulo = 'Mensaje del Servidor'
            respuesta.msgtexto = res.data
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err;
            respuesta.msgbox = true;
        })

    return respuesta
}

export const getHorarioProfesor = async (id, token) => {
    var respuesta = {}
    await axiosInstance.get('/api/profesor/getHorarioProfesor?id=' + id + '&token=' + token)
        .then(async res => {
            respuesta.operacion = true;
            respuesta.data = res.data;
        }).catch(err => {
            console.log('hubo un error: ', err);
            respuesta.operacion = false;
            respuesta.msgtexto = err;
        })
    return respuesta;
}

export const updateHorario = async (id, token, data) => {
    var respuesta = {}
    await axiosInstance.post('/api/profesor/updateHorario?id=' + id + '&token=' + token, data)
        .then(async res => {
            respuesta.operacion = true;
            respuesta.respuesta = res;
        }).catch(err => {
            console.log('hubo un error: ', err);
            respuesta.operacion = false;
        });
    return respuesta;
}

export const getAsesoriasByProfesorFuturo = async (id, token) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/getAsesoriasByProfesorFuturo?id_profesor=' + id + '&token=' + token)
        .then(async res => {
            respuesta.operacion = true;
            respuesta.data = res.data;
        }).catch(err => {
            console.log('hubo un error: ', err);
            respuesta.operacion = false;
        });
    return respuesta;
}

export const updateProfesor = async (url) => {
    var respuesta = {}
    await axiosInstance.put(url)
        .then(async res => {
            respuesta.operacion = true
            respuesta.data = res.data
        }).catch(err => {
            console.log('hubo un error: ', err);
            respuesta.operacion = false;
        });
    return respuesta;
}

export const verAsesoriaByProfesor = async (id_Asesoria_profe, id, token) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/verAsesoriaByProfesor?id_asesoria=' + id_Asesoria_profe + '&id_profesor=' + id + '&token=' + token)
        .then(async res => {
            respuesta.operacion = true
            respuesta.data_asesoria = res.data[0];
            respuesta.data_alumnos = await res.data.map(d => {
                d.estado_pago = (d.estado_pago == false ? 'Por Pagar' : 'Pagado')
            })
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false

        })

    return respuesta
}

export const updateLugarAsesoria = async (id_Asesoria_profe, lugar, id, token) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/updateLugarAsesoria?id_asesoria=' + id_Asesoria_profe + '&lugar=' + lugar + '&id_profesor=' + id + '&token=' + token)
        .then(async res => {
            if (res.data == 'Lugar Actualizado') {
                respuesta.operacion = true
                respuesta.msgtitulo = 'Actualización Realizada';
                respuesta.msgtexto = 'El Lugar fue actualizado correctamente';
                respuesta.msgbox = true;
            }

        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true;
        })

    return respuesta
}


export const cancelarAsesoriaProfe = async (id_asesoria, id, token) => {
    var respuesta = {}
    await axiosInstance.get('/api/asesoria/cancelarAsesoriaPorProfesor?id_asesoria=' + id_asesoria + '&id_alumno=' + id + '&token=' + token)
        .then(async res => {
            respuesta.data = res.data;
            respuesta.operacion = true;
            respuesta.dialog = false;
            respuesta.alert = true;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true
        })

    return respuesta
}

export const postGestionMaterialAsesoriaProfe = async (id, token, titulo, link, contenido, curso, institucion) => {
    var respuesta = {}
    await axiosInstance.post('/api/profesor/postGestionMaterialAsesoriaProfe?id_profesor=' + id + '&token=' + token +
        "&titulo=" + titulo + "&link=" + link + "&contenido=" + contenido + "&curso=" + curso + "&institucion=" + institucion)
        .then(async res => {
            respuesta.data = res.data;
            respuesta.operacion = true;
            respuesta.dialog = false;
            respuesta.alert = true;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true
        })

    return respuesta
}

export const updateGestionMaterialAsesoriaProfe = async (id, token, titulo, link, contenido, id_material) => {
    var respuesta = {}
    await axiosInstance.post('/api/profesor/updateGestionMaterialAsesoriaProfe?id_profesor=' + id + '&token=' + token +
        "&titulo=" + titulo + "&link=" + link + "&contenido=" + contenido + "&id_material=" + id_material)
        .then(async res => {
            respuesta.data = res.data;
            respuesta.operacion = true;
            respuesta.dialog = false;
            respuesta.alert = true;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true
        })

    return respuesta
}

export const getGestionMaterialAsesoriaProfe = async (id, token) => {
    var respuesta = {}
    await axiosInstance.get('/api/profesor/getGestionMaterialAsesoriaProfe?id_profesor=' + id + '&token=' + token)
        .then(async res => {
            respuesta.data = res.data;
            respuesta.operacion = true;
            respuesta.dialog = false;
            respuesta.alert = true;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true
        })

    return respuesta
}

export const getMaterialById = async (id_material) => {
    var respuesta = {}
    await axiosInstance.get('/api/profesor/getMaterialById?id_material=' + id_material)
        .then(async res => {
            respuesta.data = res.data;
            respuesta.operacion = true;
            respuesta.dialog = false;
            respuesta.alert = true;
        })
        .catch(err => {
            console.log('hubo un error: ', err)
            respuesta.operacion = false
            respuesta.msgtitulo = 'Error del Servidor:';
            respuesta.msgtexto = err.data;
            respuesta.msgbox = true
        })

    return respuesta
}

export const linkAlumnoEtiquetas = async (id, etiquetas) => {
    let data = {
        success: true,
        data: []
    };
    await axiosInstance.post(`api/profesor/vincularEtiquetaAlumno/${id}`, {
        etiquetas,
    }).then(async res => {
        data.data = res.data;
    }).catch(err => {
        console.log('hubo un error: ', err);
        data.success = false;
        data.data = err.response.data || [];
        if (!err.response?.data?.message) {
            data.data.message = 'No se pudo vincular las etiquetas';
        }
    });
    return data;
}

export const sendDifusion = async (titulo, mensaje, etiquetas) => {
    let data = {
        success: true,
        data: []
    };
    await axiosInstance.post(`api/profesor/enviarDifusion`, {
        titulo,
        mensaje,
        etiquetas
    }).then(async res => {
        data.data = res.data;
    }).catch(err => {
        console.log('hubo un error: ', err);
        data.success = false;
        data.data = err.response.data || [];
        if (!err.response?.data?.message) {
            data.data.message = 'No se pudo enviar el mensaje';
        }
    });
    return data;
}

export const updateAsistencia = async (idAsesoria, alumnos) => {
    let data = { success: false, message: "Ocurrió un error" };
    await axiosInstance.put('/api/asesoria/updateAsistencia/' + idAsesoria, {
        alumnos
    }).then(async res => {
        data.success = res.data.success;
        data.message = res.data.message;
    }).catch(err => {
        console.log('hubo un error: ', err);
    });
    return data;
}