<template>
  <v-container>

    <v-alert v-model="alert" border="left" close-text="Close Alert" color="orange" dark dismissible> {{ alert_text }}
    </v-alert>

    <v-dialog v-model="msgbox" persistent width="600px">
      <v-card>
        <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
        <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
        <v-card-text v-if="msglink != ''">
          <p class="mb-0">COMPARTIR LINK</p>
          <div class="d-flex align-center flex-wrap justify-center justify-sm-start mb-3">
            <v-sheet>
              <v-btn icon rounded color="indigo" :href="`https://www.facebook.com/sharer/sharer.php?u=${msglink}`"
                target="_blank">
                <v-icon>{{ icons.mdiFacebook }}</v-icon>
              </v-btn>
              <v-btn icon rounded color="teal"
                :href="`https://api.whatsapp.com/send?text=Únete a la asesoría en el siguiente enlace: ${msglink}`"
                target="_blank">
                <v-icon>{{ icons.mdiWhatsapp }}</v-icon>
              </v-btn>
            </v-sheet>
          </div>
          <a style="margin-right:20px" target="_blank" :href="' ' + msglink + ' '" class="text-center">{{ msglink }}</a>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" text @click="copiar">
                <img width="30" height="30" src="../../assets/copy.png" />
              </v-btn>
            </template>
            <span>Copiar Link</span>
          </v-tooltip>
        </v-card-text>
        <v-card-text class="text--primary" color="black" v-text="msgwarn"></v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="success" text @click="cerrar_mensaje">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar>
            <v-toolbar-title>Crear Asesoria</v-toolbar-title>
          </v-toolbar>



          <v-row justify="center">
            <v-col cols="12" sm="6" md="3">
              <v-text-field label="Profesor" v-model="newAsesoria.nombre_profe" required readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field label="Curso" v-model="newAsesoria.nombre_curso" required readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field label="Fecha" v-model="newAsesoria.fecha" required readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field label="Hora Inicio" v-model="newAsesoria.hora_ini" required readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field label="Hora Fin" v-model="newAsesoria.hora_fin" required readonly></v-text-field>
            </v-col>
          </v-row>

          <v-divider class="mx-4" inset></v-divider>

          <v-row>
            <v-col cols="12" sm="6" md="5">
              <v-textarea label="Temas" persistent-hint v-model="newAsesoria.descripcion"
                hint="Temas a tratar, escribirlos como lista" required auto-grow outlined></v-textarea>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <p>Tipo de Asesoria</p>
              <v-radio-group v-model="newAsesoria.tipo">
                <v-radio label="Individual" value="0"></v-radio>
                <v-radio label="Grupal" value="1"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <p>Selecciona tu medio de pago</p>
              <v-radio-group v-model="newAsesoria.tipo_pago">
                <v-radio label="Pago durante asesoría" value="Asesoria"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-btn color="success" :loading="loading" :disabled="loading" class="mr-4" @click="validar">Crear</v-btn>
            <v-btn @click="$router.go(-1)" color="warning">Regresar</v-btn>
          </v-row>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { axiosInstance } from "../../service/index";
import { mapState, mapMutations } from 'vuex'
import {
  mdiWhatsapp,
  mdiShareVariant,
  mdiFacebook
} from '@mdi/js'
export default {
  data() {
    return {
      loading: false,
      cantalumnos: 0,
      valid: false,
      icons: {
        mdiWhatsapp,
        mdiShareVariant,
        mdiFacebook
      },
      //mensaje
      msgbox: false,
      msgtitulo: '',
      msgwarn: '',
      msgtexto: '',
      msglink: '',

      //alert
      alert: false,
      alert_text: '',
    }
  },
  computed: {
    ...mapState([
      'newAsesoria',
      'userData',
      'userAlumno',
      'currentJWT',
      'userProfesor',
      'id_Asesoria',
      'procesoNoTengoCuenta',
    ]),
  },
  watch: {
  },
  methods: {
    ...mapMutations([
      'saveStateToLocalStorage',
    ]),
    validar() {
      const me = this;
      me.loading = true;
      if (me.userProfesor == true) {
        me.loading = false;
        me.alert = true;
        me.alert_text = 'Estas logeado como profesor, no puedes unirte a una asesoria';
      } else if (me.userAlumno == false) {
        me.loading = false;
        me.$store.state.procesoNoTengoCuenta = true;
        me.$store.state.crearAsesoria = true;
        this.saveStateToLocalStorage();
        me.$router.push({ name: 'alumnologin' })
      } else if (me.userAlumno == true) {
        if (me.newAsesoria.descripcion == '' || me.newAsesoria.tipo == -1 || me.newAsesoria.tipo_pago == '') {
          me.loading = false;
          me.alert = true;
          me.alert_text = 'Debe completar los campos Temas, Tipo de Asesoria, y Tipo de Pago';
        } else {
          //falta validar lo de metodo de pago de pasarela de pagos
          //en caso regrese el pago con exito, recien debe ejecutar la funcion crear_asesoria()
          me.crear_asesoria()
        }
      }
    },
    crear_asesoria() {
      const me = this;
      me.loading = true;
      let auxURL = '/api/asesoria/postCrearAsesoria?tipo=' + me.newAsesoria.tipo + '&tipo_pago=' + me.newAsesoria.tipo_pago + '&id_Profesor=' + me.newAsesoria.idProfesor + '&id_Curso=' + me.newAsesoria.idCurso + '&id_Alumno=' + me.currentJWT.id + '&lugar=por definirse&hora=' + me.newAsesoria.hora_ini + '&descripcion=' + me.newAsesoria.descripcion + '&hora_fin=' + me.newAsesoria.hora_fin + ' &fecha=' + me.newAsesoria.fecha + '&token=' + me.currentJWT.token
      axiosInstance.post(auxURL)
        .then(res => {
          me.loading = false;
          if (res.data == 'Asesoria Individual Creada') {
            me.msgbox = true;
            me.msglink = '';
            me.msgtitulo = 'Asesoria Individual Creada';
            me.msgtexto = `${me.userData.nombre}, ha creado una nueva asesoría individual `;
            me.msgwarn = '*Se le ha enviado un correo de confirmación con los detalles | Advertencia: solo puedes cancelar la asesoría si faltan 2 o más horas para que se de.';
          } else {
            me.msgbox = true;
            me.msgtitulo = 'Asesoria Grupal Creada';
            me.msgtexto = 'La información respecto al pago se le enviará a su Correo. Comparte este link para que tus compañeros se unan a la asesoria:';
            me.msglink = this.$hostname + '/alumno/unirse_asesoria/' + res.data.id_asesoria_grupal.toString();
            me.msgwarn = 'Advertencia: solo puedes cancelar la asesoría si faltan 2 o más horas para que se de.';
          }
        }).catch(err => {
          me.loading = false;
          console.log('Error:')
          console.log(err)
        })
    },
    cerrar_mensaje() {
      let me = this;
      me.msgbox = false;
      me.$router.push({ name: 'alumnoinicio' })
    },
    copiar() {
      navigator.clipboard.writeText(this.msglink);
    },
    convertDate(dateString) {
      let date_arr = dateString.split("-")
      return date_arr[2] + "-" + date_arr[1] + "-" + date_arr[0]
    }
  }
}
</script>

